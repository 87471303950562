import React from "react"
import styled from "styled-components"

const Column = styled.div<{ $mobile: boolean | undefined }>`
  display: ${({ $mobile }) => ($mobile ? "block" : "none")};
  flex: 0 0 100%;

  @media (min-width: ${({ theme }) => theme.screens.mmd}) {
    display: ${({ $mobile }) => ($mobile ? "none" : "block")};
    flex: auto;
  }

  font-size: 1rem;
  line-height: 2;

  & > div {
    display: flex;
    & > ul + ul {
      margin-left: 50px;
    }
  }
  & > span,
  & .parent-menu-link,
  & > details > summary {
    text-transform: uppercase;
    color: #000000;
    font-weight: 600;
    letter-spacing: 0.01rem;
  }

  & > details > summary {
    cursor: pointer;
    position: relative;

    &::-webkit-details-marker {
      display: none;
    }

    &:after {
      content: "◀";
      font-size: 13px;
      position: absolute;
      bottom: 2px;
      right: 0;
    }
  }

  & > details[open] > summary:after {
    content: "▼";
  }

  &:first-of-type span,
  &:first-of-type > details > summary {
    color: ${({ theme }) => theme.primaryColor};
  }
  & > ul {
  }
  & a {
    color: #000000;
    text-decoration: none;
  }
`
export default ({ column }) => {
  return (
    <>
      <Column $mobile={false}>
        {column.subMenu[0].length ? (
          <span>{column.topLevel}</span>
        ) : (
          <a
            className="parent-menu-link"
            href={column.url}
          >
            {column.topLevel}
          </a>
        )}
        <div>
          {column.subMenu[0].length
            ? column.subMenu.map((menu) => (
                <ul key={menu[0]}>
                  {menu.map((link) => (
                    <li key={link.id}>
                      <a href={link.href}>{link.name}</a>
                    </li>
                  ))}
                </ul>
              ))
            : null}
        </div>
      </Column>
      <Column $mobile>
        <details>
          <summary>{column.topLevel}</summary>
          <div>
            {column.subMenu[0].length
              ? column.subMenu.map((menu) => (
                  <ul key={menu[0].href}>
                    {menu.map((link) => (
                      <li key={link.id}>
                        <a href={link.href}>{link.name}</a>
                      </li>
                    ))}
                  </ul>
                ))
              : null}
          </div>
        </details>
      </Column>
    </>
  )
}
