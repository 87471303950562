import React from "react"
import styled from "styled-components"
import { Link } from "gatsby"
import { motion } from "framer-motion"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faSignOutAlt, faFileInvoice } from "@fortawesome/free-solid-svg-icons"
import {
  getUserAccessToken,
  logoutCustomer,
} from "../../../services/commerceLayer/customerAuth"

export const SubMenu = ({ onHover }: { onHover: (value: boolean) => void }) => {
  const userAccountLink = `https://non-stop-defense.commercelayer.app/my-account/?accessToken=${getUserAccessToken()}`
  return (
    <ColumnsMenu
      key={0}
      onMouseEnter={() => onHover(true)}
      onMouseLeave={() => onHover(false)}
      initial={{ opacity: 0, height: 0 }}
      animate={{ opacity: 1, height: "auto" }}
      exit={{ opacity: 0, height: 0 }}
      transition={{ duration: 0.3 }}
    >
      <Container>
        <ul>
          <li>
            <FontAwesomeIcon
              icon={faFileInvoice}
              size="1x"
              color="#585947"
            />
            <TextLink to={userAccountLink}>Account</TextLink>
          </li>
          <li onClick={() => logoutCustomer()}>
            <FontAwesomeIcon
              icon={faSignOutAlt}
              size="1x"
              color="#585947"
            />
            <TextLink to={`/`}>Sign out</TextLink>
          </li>
        </ul>
      </Container>
    </ColumnsMenu>
  )
}

const ColumnsMenu = styled(motion.div)`
  width: 225px;
  background-color: #eeeeee;
  position: absolute;
  left: 50%;
  top: 30px;
  transform: translate(-50%, 0);
  padding: 0;
  box-sizing: border-box;
  z-index: 4;
  overflow: hidden;
  height: 250px;
  border-radius: 7px;

  &:before {
    content: "";
    position: absolute;
    border-color: rgba(194, 225, 245, 0);
    border: solid transparent;
    border-bottom-color: white;
    border-width: 11px;
    margin-left: -10px;
    top: -21px;
    right: 65px;
    z-index: 1;
  }

  @media only screen and (max-width: ${({ theme }) => theme.screens.sm}) {
    width: 125px;
  }
`

const Container = styled.nav`
  width: 100%;
  margin: auto;
  display: flex;
  justify-content: space-between;
  text-align: center;

  & ul {
    width: 100%;

    & li {
      padding-top: 10px;
      padding-bottom: 10px;
      border-bottom: 1px solid #dddddd;
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 10px;
      flex-wrap: wrap;

      @media only screen and (max-width: ${({ theme }) => theme.screens.sm}) {
        padding-top: 7px;
        padding-bottom: 7px;
      }
    }
  }
`

const TextLink = styled(Link)`
  font-size: 16px;
  color: #242424;
  text-decoration: none;
  display: block;
  text-transform: capitalize;

  &:hover {
    color: #98896f;
    border-bottom: none;
  }

  @media only screen and (max-width: ${({ theme }) => theme.screens.sm}) {
    font-size: 14px;
  }
`
