export const getSKUName = (
  product: Queries.ProductPageQuery["product"],
  variation: Queries.ProductPageQuery["product"]["product_variants"][0]
): string | null => {
  if (!product) return null
  if (!variation) {
    return product.product_name
  }

  return [
    product.product_name,
    variation.version,
    variation.harness,
    variation.color,
    variation.size ? `size ${variation.size}` : "",
  ]
    .filter((item) => !!item)
    .join(" ")
}

export const getProductNameFromSKUName = (skuName: string): string => {
  return skuName.split("/")[0].trim()
}
