import { useState, useEffect } from "react"
import Cookies from "js-cookie"
import { authenticate } from "@commercelayer/js-auth"

const clientId = process.env.GATSBY_COMMERCELAYER_CLIENT_ID
const endpoint = process.env.GATSBY_COMMERCELAYER_ORGANIZATION
const marketId = process.env.GATSBY_US_MARKET_ID

if (!clientId) {
  throw new Error("GATSBY_COMMERCELAYER_CLIENT_ID is undefined")
}
if (!endpoint) {
  throw new Error("GATSBY_COMMERCELAYER_BASE_URL is undefined")
}
if (!marketId) {
  throw new Error("GATSBY_US_MARKET_ID is undefined")
}

export const useGetSalesChannelToken = (): string => {
  const [token, setToken] = useState("")
  useEffect(() => {
    const getCookieToken = Cookies.get(`clSalesChannelAccessToken`)
    if (!getCookieToken && clientId && endpoint) {
      const getToken = async () => {
        const auth = await authenticate("client_credentials", {
          clientId,
          scope: `market:${marketId}`,
        })
        if (auth?.accessToken) {
          setToken(auth.accessToken) // TODO: add to LocalStorage
        }

        if (auth?.accessToken && auth?.expires) {
          Cookies.set(`clSalesChannelAccessToken`, auth.accessToken, {
            expires: auth.expires,
            sameSite: "strict",
            secure: true,
          })
        }
      }
      getToken()
    } else {
      setToken(getCookieToken || "")
    }
  })
  return token
}
