import React from "react"
import { DirectusSubMenuLink } from "../MainMenuLink"
import styled from "styled-components"
import { Link } from "gatsby"
import Image from "gatsby-image"
import { motion } from "framer-motion"

export const Grid = ({
  links,
  onHover,
}: {
  links: DirectusSubMenuLink[]
  onHover: (value: boolean) => void
}) => {
  if (links.length === 0) {
    return null
  }

  const imageLinks = links
    .filter((link) => !!link.image)
    .sort((a, b) => a.position - b.position)
    .map((link) => (
      <ImageLink key={link.id} to={link.url}>
        <Image fixed={link.image?.localFile.childImageSharp.fixed} />
        <Overlay>
          <span>{link.name}</span>
        </Overlay>
      </ImageLink>
    ))

  return (
    <GridMenu
      key={links[0].id}
      onMouseEnter={() => onHover(true)}
      onMouseLeave={() => onHover(false)}
      initial={{ opacity: 0, height: 0 }}
      animate={{ opacity: 1, height: "auto" }}
      exit={{ opacity: 0, height: 0 }}
      transition={{ duration: 0.3 }}
    >
      <Container>{imageLinks}</Container>
    </GridMenu>
  )
}

const GridMenu = styled(motion.div)`
  width: 100%;
  background-color: #eeeeee;
  position: absolute;
  left: 0;
  top: 105px;
  padding: 75px 0;
  box-sizing: border-box;
  z-index: 4;
  overflow: hidden;
`

const Container = styled.nav`
  width: 1220px;
  margin: auto;
  display: grid;
  grid-template-columns: repeat(3, 387px);
  grid-template-rows: repeat(2, 175px);
  column-gap: 20px;
  row-gap: 20px;
`

const ImageLink = styled(Link)`
  color: #fff;
  font-size: 3rem;
  display: block;
  text-decoration: none;
  position: relative;
`

const Overlay = styled.div`
  display: flex;
  position: absolute;
  top: 0;
  left: 0;
  width: 387px;
  height: 175px;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.3);
  transition: all 0.3s ease-in-out 0s;

  &:hover {
    background-color: transparent;
  }
`
