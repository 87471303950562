const colorsVariable = {
  flamingoOrange: "#e9531d",
  bunkerBlack: "#101820",
  limedSpruceGrey: "#343e48",
  curiousBlue: "#1899d6",
  plum: "#913177",
  orientBlue: "#00577d",
  mineShaftGrey: "#242424",
  galleryBeige: "#eee",

  grenadierOrange: "#d03a04",

  tan: "#7d7260",
  brown: "#585947",
  gold: "#a9987b",
}

const activityColors = {
  primary_color: "#222222",
  primary_text_color: "#ffffff",
  secondary_color: "#cccccc",
  secondary_text_color: "#222222",
  link_text_hover_color: "#fe5000",
}

const screens = {
  xs: "450px",
  sm: "640px",
  md: "768px",
  mmd: "890px",
  lg: "1024px",
  xl: "1280px",
  xxl: "1350px",
  xxxl: "1600px",
  xxxxl: "1800px",
  containerWidthDesktop: "1220px",
}

const neutralPalette = {
  primaryColor: colorsVariable.gold,
  secondaryColor: colorsVariable.brown,
  contrastColor: colorsVariable.tan,
  lightBgColor: colorsVariable.galleryBeige,
  darkBgColor: colorsVariable.mineShaftGrey,

  contrastColorDarker10: colorsVariable.grenadierOrange,

  fontBase: "1rem",
  screens: { ...screens },
  activityColors: { ...activityColors },
}

const neutralPalette2 = { ...neutralPalette }
const masculinePalette = { ...neutralPalette }
const femininePalette = { ...neutralPalette }

neutralPalette2.primaryColor = colorsVariable.flamingoOrange
neutralPalette2.secondaryColor = colorsVariable.limedSpruceGrey
neutralPalette2.contrastColor = colorsVariable.curiousBlue

femininePalette.primaryColor = colorsVariable.plum
femininePalette.secondaryColor = colorsVariable.limedSpruceGrey
femininePalette.contrastColor = colorsVariable.curiousBlue

masculinePalette.primaryColor = colorsVariable.orientBlue
masculinePalette.secondaryColor = colorsVariable.limedSpruceGrey
masculinePalette.contrastColor = colorsVariable.flamingoOrange

export type ThemeType = typeof neutralPalette

export { neutralPalette, neutralPalette2, femininePalette, masculinePalette }
