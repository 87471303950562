import React from "react"
import styled from "styled-components"
import { MainMenuLink } from "./MainMenuLink"

export const MainMenuLinks = ({
  links,
  textColor,
  linkTextHoverColor,
}: {
  links: unknown[]
  textColor?: string
  linkTextHoverColor?: string
}) => {
  return (
    <nav>
      <Links>
        {links
          .filter((link) => link.display !== "mobile")
          .sort((a, b) => a.position_header - b.position_header)
          .map((link) => {
            link.url = link.url ? `/${link.url}` : ""
            return (
              <MainMenuLink
                linkTextHoverColor={linkTextHoverColor || ""}
                textColor={textColor || ""}
                key={link.id}
                link={link}
              />
            )
          })}
      </Links>
    </nav>
  )
}

const Links = styled.ul`
  display: none;
  top: ${({ theme }) => theme.screens.xl};
  @media (min-width: ${({ theme }) => theme.screens.xl}) {
    display: block;
    list-style-type: none;
    display: flex;
    flex: 1;
    margin-left: 100px;
    width: 100%;
    justify-content: space-between;
  }
`
