import { Language } from "./type"

export default {
  name: "Suomi",
  iconName: "fi",
  subFolder: `fi`,
  languageCode: `fi`,
  hreflang: "fi",

  mapDefaultCenterLat: 60.16952,
  mapDefaultCenterLng: 24.93545,

  slugs: {
    about: "tietoa-meista",
    products: "tuotteet",
    product: "tuote",
    magazine: "tietopankki",
    athletes: "urheilijat",
    stores: "myymalat",
    materials: "materiaalit",
    faq: "faq",
    jobs: "avoimet-tyopaikat",
    contact: "yhteystiedot",
    calendar: "tapahtumakalenteri",
    responsibility: "vastuullisuus",
    forHumans: "ihmisille",
    forDogs: "koirille",
    christmasCalendar: "joululahjaideat",
  },

  marketId: process.env.GATSBY_FI_MARKET_ID,
  countryCode: "FI",
  availableShippingCountries: ["FI"],
  shippingCost: "14.9",
  freeShippingMinimum: "60",
  cartUrl: "https://www.nonstopdogwear.com/fi/",
  returnUrl: "https://www.nonstopdogwear.com/fi/tuotteet/",
  privacyUrl: "https://www.nonstopdogwear.com/fi/yksityisyydensuojakayntanto/",
  termsUrl: "https://www.nonstopdogwear.com/fi/ostoehdot/",

  priceField: "finland_eur",
  currency: "EUR",
} as Language
