import { Market } from "./type"

export default {
  name: "Norge",
  marketId: process.env.GATSBY_NO_MARKET_ID,

  countryCode: "NO",
  availableShippingCountries: ["NO"],
  shippingCost: "99",
  freeShippingMinimum: "500",

  cartUrl: "https://www.nonstopdogwear.com/no/",
  returnUrl: "https://www.nonstopdogwear.com/no/produkter/",
  privacyUrl: "https://www.nonstopdogwear.com/no/personvernerklaering/",
  termsUrl: "https://www.nonstopdogwear.com/no/kjopsbetingelser/",
  priceField: "norway_nok",
  currency: "NOK",
} as Market
