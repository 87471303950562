import axios from "axios"
import { Language } from "../../../config/languages/type"
import { getAccessToken } from "./auth"
import { getOrderCookie, removeOrderCookie, setOrderCookie } from "./cookies"
import { LineItem, LineItemWithInventory } from "./line-item"
import { Sku } from "./skus"

type Order = {
  id: string
  type: string
  attributes: {
    cart_url: string
    checkout_url: string
    country_code: string | null
    coupon_code: string | null
    currency_code: string
    customer_email: string | null
    editable: boolean
    subtotal_amount_cents: number
    guest: boolean
    language_code: string
    metadata: {
      [key: string]: string
    }
    number: number
    reference: null | string
    reference_origin: null | string
    return_url: string
    skus_count: number
    shipping_country_code_lock: string | null
    status: string
    terms_url: string
    total_amount_with_taxes_cents: number
  }
  relationships: {
    line_items: { data: { type: string; id: string }[] }
  }
}

export type OrderWithData = Order & { lineItems: LineItem[] }

export async function getOrder({
  marketId,
  shippingCountry,
  locale,
}: {
  marketId?: string
  shippingCountry: string
  locale: Language
}) {
  if (!marketId) {
    throw new Error("No market Id provided")
  }
  let orderId = getOrderCookie({ marketId, countryCode: shippingCountry })
  if (orderId) {
    const order = await fetchOrder({ orderId, marketId })
    if (["draft", "pending"].includes(order.attributes.status)) {
      return order
    }
    removeOrderCookie(shippingCountry, marketId)
    return createOrder({ marketId, shippingCountry, locale })
  } else {
    return createOrder({ marketId, shippingCountry, locale })
  }
}

function addInventoryToLineItem(
  included: (
    | (LineItem & {
        relationships: {
          item: { data: { type: string; id: string } }
        }
      })
    | (Sku & {
        attributes: { inventory: { available: boolean; quantity: number } }
      })
  )[]
): LineItemWithInventory[] {
  return included
    .filter(
      (
        inc
      ): inc is LineItem & {
        relationships: {
          item: { data: { type: string; id: string } }
        }
      } => inc.type === "line_items"
    )
    .filter((lineItem) => lineItem.relationships.item.data.type === "skus")
    .map((lineItem) => {
      const sku = included.find(
        (
          inc
        ): inc is Sku & {
          attributes: { inventory: { available: boolean; quantity: number } }
        } => inc.id === lineItem.relationships.item.data.id
      )
      const lineItemWithInventory: LineItem & {
        relationships: {
          item: { data: { type: string; id: string } }
        }
        inventory?: number
      } = { ...lineItem }
      lineItemWithInventory.inventory =
        sku && sku.type === "skus" ? sku.attributes.inventory.quantity : 1
      return lineItemWithInventory
    })
}

const orderFields = [
  "cart_url",
  "checkout_url",
  "country_code",
  "coupon_code",
  "currency_code",
  "customer_email",
  "guest",
  "language_code",
  "line_items",
  "metadata",
  "number",
  "reference_origin",
  "reference",
  "return_url",
  "shipping_country_code_lock",
  "skus_count",
  "status",
  "subtotal_amount_cents",
  "terms_url",
  "total_amount_with_taxes_cents",
]

const lineItemsFields = [
  "sku_code",
  "quantity",
  "formatted_total_amount",
  "unit_amount_cents",
  "metadata",
  "name",
  "image_url",
  "item_type",
  "item",
]

export async function fetchOrder({
  orderId,
  marketId,
}: {
  orderId: string
  marketId: string
}) {
  const accessToken = await getAccessToken(marketId)
  const response = await axios.get<{
    data: Order
    included?: {
      id: string
      type: string
      attributes: { [key: string]: unknown }
      relationships: { [key: string]: unknown }
    }[]
  }>(
    `${
      process.env.GATSBY_COMMERCELAYER_BASE_URL
    }/api/orders/${orderId}?fields[orders]=${orderFields.join(
      ","
    )}&fields[line_items]=${lineItemsFields.join(
      ","
    )}&include=line_items,line_items.item`,
    {
      headers: { Authorization: `Bearer ${accessToken}` },
    }
  )

  const lineItemsWithData = response.data.included
    ? addInventoryToLineItem(
        response.data.included.filter(
          (inc) => inc.type === "line_items" || inc.type === "skus"
        )
      )
    : []
  return { ...response.data.data, lineItems: lineItemsWithData }
}

export function getRecommendedSize(): object | undefined {
  const sizeData = localStorage.getItem("recommended_size") || ""
  try {
    return sizeData ? JSON.parse(sizeData) : undefined
  } catch (e) {
    return undefined
  }
}

function getTrafficSource(): {
  referrer: string
  utm_medium: string
  utm_source: string
  utm_content: string
  utm_campaign: string
} {
  return {
    referrer: sessionStorage.getItem("referrer") || "",
    utm_medium: sessionStorage.getItem("utm_medium") || "",
    utm_source: sessionStorage.getItem("utm_source") || "",
    utm_content: sessionStorage.getItem("utm_content") || "",
    utm_campaign: sessionStorage.getItem("utm_campaign") || "",
  }
}

async function createOrder({
  marketId,
  shippingCountry,
  locale,
}: {
  marketId: string
  shippingCountry: string
  locale: Language
}): Promise<OrderWithData> {
  const accessToken = await getAccessToken(marketId)
  const response = await axios.post<{
    data: Order
  }>(
    `${process.env.GATSBY_COMMERCELAYER_BASE_URL}/api/orders/`,
    {
      data: {
        type: "orders",
        attributes: {
          shipping_country_code_lock: shippingCountry,
          language_code: locale.languageCode,
          cart_url: locale.cartUrl,
          return_url: locale.returnUrl,
          privacy_url: locale.privacyUrl,
          terms_url: locale.termsUrl,
          metadata: {
            userAgent: navigator.userAgent,
            sources: [getTrafficSource()],
            recommendedSize: [getRecommendedSize()],
          },
        },
      },
    },
    {
      headers: {
        "Authorization": `Bearer ${accessToken}`,
        "Content-Type": "application/vnd.api+json",
      },
    }
  )
  setOrderCookie(shippingCountry, response.data.data.id, marketId)
  sessionStorage.setItem("addedTrafficSource", "true")

  return { ...response.data.data, lineItems: [] }
}

export async function updateOrder({
  marketId,
  orderId,
  attributes,
}: {
  marketId: string
  orderId: string
  attributes: { [key: string]: any }
}) {
  const accessToken = await getAccessToken(marketId)
  try {
    await axios.patch<{
      data: Order
    }>(
      `${process.env.GATSBY_COMMERCELAYER_BASE_URL}/api/orders/${orderId}`,
      {
        data: {
          id: orderId,
          type: "orders",
          attributes: {
            ...attributes,
          },
        },
      },
      {
        headers: {
          "Authorization": `Bearer ${accessToken}`,
          "Content-Type": "application/vnd.api+json",
        },
      }
    )
  } catch (error) {
  } finally {
    return fetchOrder({ orderId, marketId })
  }
}

async function getOrderTrafficSources({
  marketId,
  orderId,
}: {
  marketId: string
  orderId: string
}): Promise<any[]> {
  const accessToken = await getAccessToken(marketId)
  const order = await axios.get<{
    data: {
      id: string
      type: string
      attributes: {
        metadata: {
          [key: string]: any
          sources?: any[]
        }
      }
    }
  }>(
    `${process.env.GATSBY_COMMERCELAYER_BASE_URL}/api/orders/${orderId}?fields[orders]=metadata`,
    {
      headers: { Authorization: `Bearer ${accessToken}` },
    }
  )

  return order.data.data.attributes.metadata.sources || []
}

export async function updateTrafficSources({
  marketId,
  orderId,
}: {
  marketId?: string | undefined
  orderId: string
}) {
  if (sessionStorage.getItem("addedTrafficSource") !== "true") {
    let sources = await getOrderTrafficSources({ marketId, orderId })
    if (!Array.isArray(sources)) {
      sources = []
    }
    sources.push(getTrafficSource())
    await updateOrder({
      marketId,
      orderId,
      attributes: {
        metadata: { sources: sources, recommendedSize: [getRecommendedSize()] },
      },
    })
    sessionStorage.setItem("addedTrafficSource", "true")
  }
}
