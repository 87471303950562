import React from "react"
import styled from "styled-components"
import { Link, useStaticQuery, graphql } from "gatsby"

import Container from "../Container"
import termLinksData from "./termLinksData"

export default function TermsLine() {
  const copyrightTextQuery: Queries.copyrightTextQuery = useStaticQuery(graphql`
    query copyrightText {
      strapiSmallText {
        copyright
      }
    }
  `)
  const copyright = copyrightTextQuery?.strapiSmallText?.copyright || ""
  return (
    <Line>
      <div>
        {termLinksData().map((link) => (
          <TermLink
            key={link.id}
            to={link.href}
          >
            {link.name}
          </TermLink>
        ))}
        <CopyrightContent>
          <Copyright>{copyright}</Copyright>
        </CopyrightContent>
      </div>
    </Line>
  )
}

export const CopyrightContent = styled.div`
  display: inline-block;
  margin-left: 50px;
  @media (max-width: ${({ theme }) => theme.screens.mmd}) {
    margin-left: 0;
    margin-top: 15px;
  }
`

export const Copyright = styled.p`
  color: #8e8e8e;
  font-size: 12px;
  margin: 0;
`

export const Line = styled(Container)`
  position: relative;
  background-color: #000;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  border-top: 1px solid #000;
  @media (min-width: ${({ theme }) => theme.screens.xl}) {
    padding-top: 13px;
    padding-bottom: 13px;
  }

  & svg + svg {
    margin-left: 15px;
  }
`

export const TermLink = styled(Link)`
  text-decoration: none;
  margin-right: 1rem;
  margin-bottom: 15px;
  display: inline-block;
  color: #ffffff;
  width: 100%;
  font-size: 0.9rem;

  @media (min-width: ${({ theme }) => theme.screens.mmd}) {
    width: auto;
    margin-bottom: 0px;
    &:not(:first-child):before {
      content: "|";
      color: #8e8e8e;
      padding-right: 1rem;
    }
  }
`
