import styled from "styled-components"
import CSS from "csstype"

export default styled.section<CSS.Properties>`
  background-color: ${({ background }) => background || "#fff"};
  padding: 20px 23px;
  max-width: 100%;
  @media (min-width: ${({ theme }) => theme.screens.mmd}) {
    padding: 20px 30px;
    max-width: 100%;
  }

  @media (min-width: ${({ theme }) => theme.screens.xl}) {
    padding: 20px
      calc((100% - ${({ theme }) => theme.screens.containerWidthDesktop}) / 2);
    padding-top: ${({ paddingTop }) => paddingTop || "20px"};
    padding-bottom: ${({ paddingBottom }) => paddingBottom || "20px"};
    max-width: 100%;
  }
`
