import React from "react"
import styled from "styled-components"
import { NewsletterInlineForm } from "./InlineForm"
export function NewsletterLine() {
  return (
    <Line id="newsletter">
      <NewsletterInlineForm />
    </Line>
  )
}

export const Line = styled.div`
  background-color: #646059;
  color: #fff;
  font-size: 1.8rem;
  padding: 20px;
  max-width: 100%;
  display: flex;
  justify-content: center;
  & > form,
  & > div {
    margin: auto;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;

    @media (min-width: ${({ theme }) => theme.screens.xl}) {
      width: 1220px;
    }
  }

  & .title {
    text-transform: uppercase;
    margin-bottom: 20px;
    font-size: 2rem;

    @media (min-width: ${({ theme }) => theme.screens.xl}) {
      margin-bottom: 0;
    }
  }

  & .description {
    font-size: 1rem;
    line-height: 1.2;
    max-width: 410px;
    margin-bottom: 20px;

    @media (min-width: ${({ theme }) => theme.screens.xl}) {
      margin-bottom: 0;
    }
  }

  & input[type="email"] {
    padding: 10px 8px;
    background: transparent;
    color: #fff;
    border: 1px solid #fff;
    margin-right: 10px;
    text-align: center;
    width: 225px;
    font-size: 16px;
  }

  & input[type="submit"] {
    background: #242424;
    color: #fff;
    cursor: pointer;
    display: inline-block;
    text-decoration: none;
    text-transform: uppercase;
    padding: 12px;
    position: relative;
    border: 0;
    font-size: 14px;

    &:hover {
      background-color: #fff;
      color: #000;
    }
    &:disabled {
      background-color: #24242499;
      &:hover {
        cursor: not-allowed;
      }
    }
  }
`
