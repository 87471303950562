import locales from "../../config/languages"
import slugify from "slugify"

slugify.extend({ "®": "" })
slugify.extend({ "+": "-plus" })

const siteUrl = "https://www.nonstopdogwear.com"

export function getProductUrl(subFolder: string, productName: string): string {
  const productSlug = slugify(productName, {
    lower: true,
    remove: /[.0]/g,
  })
  const locale = Object.values(locales).find(
    locale => locale.subFolder === subFolder
  )
  const productPrefixSlug = locale ? locale.slugs.product : "p"

  return locale
    ? `${siteUrl}/${subFolder}/${productPrefixSlug}/${productSlug}/`
    : `${siteUrl}/${productPrefixSlug}/${productSlug}/`
}
