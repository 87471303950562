import { Market } from "./type"

export default {
  name: "Sverige",
  iconName: "se",
  subFolder: `se`,
  marketId: process.env.GATSBY_SE_MARKET_ID,

  countryCode: "SE",
  availableShippingCountries: ["SE"],
  shippingCost: "149",
  freeShippingMinimum: "600",

  cartUrl: "https://www.nonstopdogwear.com/se/",
  returnUrl: "https://www.nonstopdogwear.com/se/produkter/",
  privacyUrl: "https://www.nonstopdogwear.com/se/integritetsforklaring/",
  termsUrl: "https://www.nonstopdogwear.com/se/kopvillkor/",
  priceField: "sweden_sek",
  currency: "SEK",
} as Market
