export const metricToImperialUnit = new Map()

metricToImperialUnit.set("cm", "in")
metricToImperialUnit.set("kg", "lbs")

const mmToIn = (input: number) => ({
  value: (input / 25.4).toFixed(1),
  unit: "in",
})

const cmToIn = (input: number) => ({
  value: (input / 2.54).toFixed(1),
  unit: "in",
})

const mToFt = (input: number) => ({
  value: Math.floor(input * 3.281).toFixed(0),
  unit: "′",
  secondaryValue: (input * 39.37).toFixed(0) % 12,
  secondaryUnit: "″",
})

const gToOz = (input: number) => ({
  value: (input / 28.35).toFixed(1),
  unit: " oz",
})

const kgToLbs = (input: number) => ({
  value: (input * 2.20462).toFixed(1),
  unit: "lbs",
})

const mlToFlOz = (input: number) => ({
  value: (input / 29.574).toFixed(1),
  unit: "fl oz",
})

const metricToImperial = new Map()

metricToImperial.set("mm", mmToIn)
metricToImperial.set("cm", cmToIn)
metricToImperial.set("m", mToFt)
metricToImperial.set("g", gToOz)
metricToImperial.set("kg", kgToLbs)
metricToImperial.set("mL", mlToFlOz)

export const convertSystem = (
  isMetric: boolean,
  { value, unit }: { value: string | number; unit: string }
) => {
  if (isMetric) {
    return `${value}\u00A0${unit}`
  }

  if (!metricToImperial.has(unit)) {
    return `${value}\u00A0${unit}`
  }

  const conversionFunction = metricToImperial.get(unit)

  const separator = getSeparator(value)
  const measurementValues = value.split(separator)
  const convertedData = conversionFunction(measurementValues)
  const imperialUnit = convertedData.unit

  if ("secondaryUnit" in convertedData) {
    const { secondaryUnit } = convertedData

    return measurementValues
      .map((mValue) => {
        const convertedData = conversionFunction(mValue)
        const { value, secondaryValue } = convertedData

        return `${value}${imperialUnit}\u00A0${secondaryValue}${secondaryUnit}`
      })
      .join(` ${separator} `)
  }

  return `${measurementValues
    .map((mValue) => `${conversionFunction(mValue).value}`)
    .join(` ${separator} `)}\u00A0${imperialUnit}`
}

const inToCm = (input: number) => (input * 2.54).toFixed(1)
const lbsToKg = (input: number) => (input / 2.205).toFixed(1)

const ImperialToMetric = new Map()

ImperialToMetric.set("cm", inToCm)
ImperialToMetric.set("kg", lbsToKg)

export const convertToMetricNumber = (
  number: number,
  unit: string,
  isImperial: boolean
) => {
  if (!isImperial) {
    return number
  }
  if (ImperialToMetric.has(unit)) {
    const conversionFunction = ImperialToMetric.get(unit)
    const convertedData = conversionFunction(number)
    return convertedData
  }
  return number
}

const getSeparator = (value: string[], separators = ["x", "-"]) => {
  const separatorUsed = separators.filter((separator) =>
    value.includes(separator)
  )
  if (!Array.isArray(separatorUsed) || !separatorUsed.length) {
    return false
  }
  return separatorUsed[0]
}

const sizesOrder = [
  "1-pate",
  "10-pate-2-extra",
  "small",
  "medium",
  "1",
  "2",
  "3",
  "4",
  "4.5",
  "5",
  "5.5",
  "6",
  "6.5",
  "7",
  "7.5",
  "8",
  "8.5",
  "9",
  "9.5",
  "10",
  "10.5",
  "24",
  "27",
  "30",
  "33",
  "35",
  "36",
  "40",
  "45",
  "50",
  "55",
  "60",
  "65",
  "70",
  "74",
  "80",
  "90",
  "1-m",
  "2-m",
  "2-8-m",
  "3-m",
  "2-m-13-mm",
  "2-8-m-13-mm",
  "2-8-m-23-mm",
  "3-8-m-23-mm",
  "10mm/1.5m",
  "15mm/1.5m",
  "20mm/1.7m",
  "10-mm-1-5-m",
  "15-mm-1-5-m",
  "20-mm-1-7-m",
  "50-ml",
  "100-ml",
  "300-ml",
  "500-ml",
  "junior",
  "original",
  "6xs",
  "5xs",
  "4xs",
  "3xs",
  "xxxs",
  "2xs",
  "xxs",
  "xs",
  "s",
  "m",
  "l",
  "xl",
  "xxl",
  "2xl",
  "xxxl",
  "3xl",
  "4xl",
  "5xl",
]

export const orderSizesSelect = (a, b) =>
  sizesOrder.findIndex(
    (i) => i === a?.attributes?.metadata?.size.toLowerCase()
  ) -
  sizesOrder.findIndex((i) => i === b?.attributes?.metadata?.size.toLowerCase())

export const orderSizesChartHeading = (a, b) => {
  const value1 = isNaN(a) ? a.toLowerCase() : a
  const value2 = isNaN(b) ? b.toLowerCase() : b
  return (
    sizesOrder.findIndex((i) => i === value1.toLowerCase()) -
    sizesOrder.findIndex((i) => i === value2.toLowerCase())
  )
}

export const orderSizesChartBody = (a, b) =>
  sizesOrder.findIndex((i) => i === a.size.toLowerCase()) -
  sizesOrder.findIndex((i) => i === b.size.toLowerCase())

export const orderSizesChartBodyAllView = (a, b) => {
  if (a?.row && b?.row) {
    return (
      sizesOrder.findIndex((i) => i === a.row.toLowerCase()) -
      sizesOrder.findIndex((i) => i === b.row.toLowerCase())
    )
  } else {
    return (
      sizesOrder.findIndex((i) => i === a.toLowerCase()) -
      sizesOrder.findIndex((i) => i === b.toLowerCase())
    )
  }
}

export const orderSizesChartMobile = (a, b) =>
  sizesOrder.findIndex((i) => i === a.key.toLowerCase()) -
  sizesOrder.findIndex((i) => i === b.key.toLowerCase())

export const orderBySku = (a, b) => a.code.toLowerCase() - b.code.toLowerCase()

export const formatCurrency = ({ amount = 0 }: { amount: number }) => {
  const languageCode = "en"
  const currency = "USD"
  const formatter = new Intl.NumberFormat(languageCode, {
    style: "currency",
    currency,
  })
  return formatter.format(amount)
}

export const groupProductsByCategory = (
  products: Queries.ActivityQuery["strapiActivity"]["products"]
) => {
  if (!products) {
    return {}
  }
  let data = {}

  for (let i = 0; i < products.length; i++) {
    const product = products[i]
    const productCategory = product.product_category
      ? product.product_category.category_name
      : "Others"
    if (!productCategory) continue // "jumps over" one iteration

    if (!data[productCategory]) data[productCategory] = []
    data[productCategory].push(product)

    if (!product?.secondary_categories) continue // "jumps over" one iteration
    const subCategories = product.secondary_categories
      .map((elem) => elem?.category_name)
      .filter((elem) => elem !== productCategory) // filter out a primary category

    subCategories.forEach((elem) => {
      if (!elem) return // "jumps over" one iteration of forEach
      if (!data[elem]) data[elem] = []
      data[elem].push(product)
    })
  }

  return removeCategoryNoProducts(data)
}

const removeCategoryNoProducts = (categories) => {
  let filteredCategories = {}
  for (let cat in categories) {
    const products = categories[cat].filter((c) => c.display)
    if (products.length) filteredCategories[cat] = categories[cat]
  }

  return filteredCategories
}

export const sortCategories = (obj, orders) => {
  return Object.keys(obj)
    .sort((a: string, b: string) => {
      return (
        orders.findIndex((i) => i.toLowerCase() === a.toLowerCase()) -
        orders.findIndex((i) => i.toLowerCase() === b.toLowerCase())
      )
    })
    .reduce(function (result, key) {
      result[key] = obj[key]
      return result
    }, {})
}

export const combineCategoryProducts = (
  categoryProducts: Strapi_Product_CategoryEdge[] | undefined
) => {
  if (!categoryProducts) {
    return []
  }

  let data: Strapi_Product[] = []
  const catProducts = categoryProducts.map((edge) => edge.node.products)

  catProducts.forEach((products) => {
    if (products) {
      products?.forEach((p) => {
        if (p) data.push(p)
      })
    }
  })

  return data
}

export const isProductForHumans = (product: Strapi_Product): boolean => {
  if (product.target && product.target.strapi_json_value)
    return product.target.strapi_json_value.some((t) => {
      return t === "For humans"
    })
  else return false
}

export const isStringHTML = (string: string | null | undefined): boolean =>
  !!string && /<([A-Za-z][A-Za-z0-9]*)\b[^>]*>(.*?)<\/\1>/.test(string)
