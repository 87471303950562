import { Language } from "./type"

export default {
  name: "English",
  iconName: "int",
  subFolder: `en`,

  languageCode: `en`,
  hreflang: "en",

  mapDefaultCenterLat: 38.89511,
  mapDefaultCenterLng: -77.03637,

  slugs: {
    about: "about",
    products: "products",
    product: "product",
    magazine: "magazine",
    athletes: "athletes",
    stores: "stores",
    materials: "materials",
    faq: "faq",
    jobs: "jobs",
    contact: "contact",
    calendar: "activity-calendar",
    responsibility: "our-responsibility",
    forHumans: "for-humans",
    forDogs: "for-dogs",
    christmasCalendar: "christmas-gift-guide",    
  },
  marketId: process.env.GATSBY_US_MARKET_ID,
  countryCode: "",
  availableShippingCountries: [
    "US",
    "AF",
    "DZ",
    "AS",
    "AO",
    "AI",
    "AQ",
    "AG",
    "AR",
    "AM",
    "AW",
    "AU",
    "AZ",
    "BS",
    "BH",
    "BD",
    "BB",
    "BZ",
    "BJ",
    "BM",
    "BT",
    "BO",
    "BW",
    "BV",
    "BR",
    "IO",
    "BN",
    "BF",
    "BI",
    "KH",
    "CM",
    "CA",
    "CV",
    "KY",
    "CF",
    "TD",
    "CL",
    "CN",
    "CX",
    "CC",
    "CO",
    "KM",
    "CG",
    "CK",
    "CR",
    "CU",
    "DJ",
    "DM",
    "DO",
    "TP",
    "EC",
    "EG",
    "SV",
    "GQ",
    "ER",
    "ET",
    "FK",
    "FO",
    "FJ",
    "PF",
    "GA",
    "GM",
    "GE",
    "GH",
    "GD",
    "GU",
    "GT",
    "GN",
    "GW",
    "GY",
    "HT",
    "HM",
    "HN",
    "HK",
    "IN",
    "ID",
    "IR",
    "IQ",
    "IL",
    "CI",
    "JM",
    "JP",
    "JO",
    "KZ",
    "KE",
    "KI",
    "KW",
    "KG",
    "LA",
    "LB",
    "LS",
    "LR",
    "LY",
    "LI",
    "MO",
    "MK",
    "MG",
    "MW",
    "MY",
    "MV",
    "ML",
    "MH",
    "MR",
    "MU",
    "MX",
    "FM",
    "MN",
    "MS",
    "MA",
    "MZ",
    "MM",
    "NA",
    "NR",
    "NP",
    "AN",
    "NZ",
    "NI",
    "NE",
    "NG",
    "NU",
    "NF",
    "KP",
    "MP",
    "OM",
    "PK",
    "PW",
    "PS",
    "PA",
    "PG",
    "PY",
    "PE",
    "PH",
    "PN",
    "PR",
    "QA",
    "RW",
    "SH",
    "KN",
    "LC",
    "PM",
    "VC",
    "WS",
    "ST",
    "SA",
    "SN",
    "SC",
    "SL",
    "SG",
    "SB",
    "SO",
    "ZA",
    "GS",
    "KR",
    "SS",
    "LK",
    "SD",
    "SR",
    "SJ",
    "SZ",
    "SY",
    "TJ",
    "TZ",
    "TH",
    "CD",
    "TG",
    "TK",
    "TO",
    "TT",
    "TN",
    "TR",
    "TM",
    "TC",
    "TV",
    "TW",
    "UG",
    "UA",
    "AE",
    "UM",
    "UY",
    "UZ",
    "VU",
    "VE",
    "VN",
    "VG",
    "VI",
    "WF",
    "EH",
    "YE",
    "YU",
    "ZM",
    "ZW",
  ],
  recommendedCountries: [
    "BR",
    "AF",
    "DZ",
    "AS",
    "AO",
    "AI",
    "AQ",
    "AG",
    "AM",
    "AW",
    "AU",
    "AZ",
    "BS",
    "BH",
    "BD",
    "BB",
    "BZ",
    "BJ",
    "BM",
    "BT",
    "BW",
    "BV",
    "IO",
    "BN",
    "BF",
    "BI",
    "KH",
    "CM",
    "CA",
    "CV",
    "KY",
    "CF",
    "TD",
    "CN",
    "CX",
    "CC",
    "KM",
    "CG",
    "CK",
    "CR",
    "DJ",
    "DM",
    "TP",
    "EG",
    "ER",
    "ET",
    "FK",
    "FO",
    "FJ",
    "PF",
    "GA",
    "GM",
    "GE",
    "GH",
    "GD",
    "GU",
    "GN",
    "GW",
    "GY",
    "HT",
    "HM",
    "HN",
    "HK",
    "IN",
    "ID",
    "IR",
    "IQ",
    "IL",
    "CI",
    "JM",
    "JP",
    "JO",
    "KZ",
    "KE",
    "KI",
    "KW",
    "KG",
    "LA",
    "LB",
    "LS",
    "LR",
    "LY",
    "LI",
    "MO",
    "MK",
    "MG",
    "MW",
    "MY",
    "MV",
    "ML",
    "MH",
    "MR",
    "MU",
    "FM",
    "MN",
    "MS",
    "MA",
    "MZ",
    "MM",
    "NA",
    "NR",
    "NP",
    "AN",
    "NZ",
    "NI",
    "NE",
    "NG",
    "NU",
    "NF",
    "KP",
    "MP",
    "OM",
    "PK",
    "PW",
    "PS",
    "PG",
    "PH",
    "PN",
    "PR",
    "QA",
    "RW",
    "SH",
    "KN",
    "LC",
    "PM",
    "VC",
    "WS",
    "ST",
    "SA",
    "SN",
    "SC",
    "SL",
    "SG",
    "SB",
    "SO",
    "ZA",
    "GS",
    "KR",
    "SS",
    "LK",
    "SD",
    "SR",
    "SJ",
    "SZ",
    "SY",
    "TJ",
    "TZ",
    "TH",
    "CD",
    "TG",
    "TK",
    "TO",
    "TT",
    "TN",
    "TR",
    "TM",
    "TC",
    "TV",
    "TW",
    "UG",
    "UA",
    "AE",
    "US",
    "UM",
    "UY",
    "UZ",
    "VU",
    "VN",
    "VG",
    "VI",
    "WF",
    "EH",
    "YE",
    "YU",
    "ZM",
    "ZW",
  ],
  shippingCost: "14.9",
  freeShippingMinimum: "60",

  cartUrl: "https://www.nonstopdogwear.com/en/",
  returnUrl: "https://www.nonstopdogwear.com/en/products/",
  privacyUrl: "https://www.nonstopdogwear.com/en/privacy/",
  termsUrl: "https://www.nonstopdogwear.com/en/terms/",
  priceField: "international_usd",
  currency: "USD",
} as Language
