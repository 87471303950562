exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-templates-about-tsx": () => import("./../../../src/templates/About.tsx" /* webpackChunkName: "component---src-templates-about-tsx" */),
  "component---src-templates-activity-tsx": () => import("./../../../src/templates/Activity.tsx" /* webpackChunkName: "component---src-templates-activity-tsx" */),
  "component---src-templates-contact-tsx": () => import("./../../../src/templates/Contact.tsx" /* webpackChunkName: "component---src-templates-contact-tsx" */),
  "component---src-templates-cookies-tsx": () => import("./../../../src/templates/Cookies.tsx" /* webpackChunkName: "component---src-templates-cookies-tsx" */),
  "component---src-templates-faq-tsx": () => import("./../../../src/templates/Faq.tsx" /* webpackChunkName: "component---src-templates-faq-tsx" */),
  "component---src-templates-forgot-password-form-tsx": () => import("./../../../src/templates/ForgotPasswordForm.tsx" /* webpackChunkName: "component---src-templates-forgot-password-form-tsx" */),
  "component---src-templates-home-page-tsx": () => import("./../../../src/templates/HomePage.tsx" /* webpackChunkName: "component---src-templates-home-page-tsx" */),
  "component---src-templates-impressum-tsx": () => import("./../../../src/templates/Impressum.tsx" /* webpackChunkName: "component---src-templates-impressum-tsx" */),
  "component---src-templates-login-form-tsx": () => import("./../../../src/templates/LoginForm.tsx" /* webpackChunkName: "component---src-templates-login-form-tsx" */),
  "component---src-templates-privacy-policy-tsx": () => import("./../../../src/templates/PrivacyPolicy.tsx" /* webpackChunkName: "component---src-templates-privacy-policy-tsx" */),
  "component---src-templates-product-category-tsx": () => import("./../../../src/templates/ProductCategory.tsx" /* webpackChunkName: "component---src-templates-product-category-tsx" */),
  "component---src-templates-product-tsx": () => import("./../../../src/templates/Product.tsx" /* webpackChunkName: "component---src-templates-product-tsx" */),
  "component---src-templates-registration-form-tsx": () => import("./../../../src/templates/RegistrationForm.tsx" /* webpackChunkName: "component---src-templates-registration-form-tsx" */),
  "component---src-templates-reset-password-form-tsx": () => import("./../../../src/templates/ResetPasswordForm.tsx" /* webpackChunkName: "component---src-templates-reset-password-form-tsx" */),
  "component---src-templates-terms-conditions-tsx": () => import("./../../../src/templates/TermsConditions.tsx" /* webpackChunkName: "component---src-templates-terms-conditions-tsx" */)
}

