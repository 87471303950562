import React from "react"
import { DirectusSubMenuLink } from "../MainMenuLink"
import styled from "styled-components"
import { Link } from "gatsby"
import { motion } from "framer-motion"

export const Angle = ({
	links,
	onHover,
}: {
	links: DirectusSubMenuLink[]
	onHover: (value: boolean) => void
}) => {
	if (links.length === 0) {
		return null
	}
	const organizedLinks = links
		.filter((link) => link.parent_submenu === null)
		.sort((a, b) => a.position - b.position)
		.map((link) => (
			<Column key={link.id}>
				<Header>{link.name}</Header>
				<ul>
					{links
						.filter((subLink) => !!subLink.parent_submenu)
						.filter(
							(subLink) => subLink.parent_submenu.id === link.id
						)
						.sort((a, b) => a.position - b.position)
						.map((subLink) => (
							<li key={subLink.id}>
								<TextLink to={subLink.url}>
									{subLink.name}
								</TextLink>
							</li>
						))}
				</ul>
			</Column>
		))

	return (
		<AngleMenu
			key={links[0].id}
			onMouseEnter={() => onHover(true)}
			onMouseLeave={() => onHover(false)}
			initial={{ opacity: 0, height: 0 }}
			animate={{ opacity: 1, height: "auto" }}
			exit={{ opacity: 0, height: 0 }}
			transition={{ duration: 0.3 }}
		>
			<Container>{organizedLinks}</Container>
		</AngleMenu>
	)
}

const AngleMenu = styled(motion.nav)`
	width: 100%;
	background-color: #eeeeee;
	position: absolute;
	left: 0;
	top: 105px;
	padding: 75px 0;
	box-sizing: border-box;
	z-index: 4;
	overflow: hidden;
`

const Container = styled.ul`
	width: 1220px;
	margin: auto;
	display: flex;
	justify-content: space-between;
`

const Header = styled.span`
	font-size: 1.8rem;
	color: #242424;
	font-weight: bold;
	text-transform: uppercase;
	line-height: 1.806;
`

const Column = styled.li`
	padding-left: 10px;
	&:last-child,
	&:nth-last-child(2) {
		border-left: 1px solid #212121;

		& ${Header} {
			color: #98896f;
		}
	}
`

const TextLink = styled(Link)`
	font-size: 1.8rem;
	color: #242424;
	text-decoration: none;
	display: block;
	padding: 10px 0;
	text-transform: none;

	&:hover {
		color: #98896f;
	}
`
