export default () => [
  {
    id: 1,
    name: "Terms and conditions of sale",
    href: `/terms/`,
  },
  {
    id: 2,
    name: "Privacy policy",
    href: `/privacy/`,
  },
  {
    id: 3,
    name: "Cookies",
    href: `/cookies/`,
  },
  {
    id: 4,
    name: "Impressum",
    href: `/impressum/`,
  },
]
