import styled from "styled-components"

export default styled.h2`
	text-transform: uppercase;
	display: flex;
	width: 100%;
	align-items: center;
	font-size: 1.2rem;
	letter-spacing: 0.25rem;
	color: #1b1b1b;
	margin-bottom: 1.5rem;

	&::after {
		content: "";
		border-bottom: 1px solid #000;
		flex: 1 0 20px;
		margin-left: 20px;
	}
`
