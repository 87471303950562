import type { ReactNode } from "react"
import React, { createContext, useContext } from "react"

const LocationContext = createContext<Location | null>(null)

export function LocationProvider({
  location,
  children,
}: {
  location: Location
  children: ReactNode
}) {
  return (
    <LocationContext.Provider value={location}>
      {children}
    </LocationContext.Provider>
  )
}

export const useLocation = (): Location => {
  const location = useContext(LocationContext)

  if (location == null) {
    throw new Error("useLocation must be used inside LocationProvider")
  }

  return location
}
