import { Language } from "./type"

export default {
  name: "Norsk",
  iconName: "no",
  subFolder: `no`,

  languageCode: `nb`,
  hreflang: "nb",

  mapDefaultCenterLat: 59.91273,
  mapDefaultCenterLng: 10.74609,

  slugs: {
    about: "om-oss",
    products: "produkter",
    product: "produkt",
    magazine: "magasin",
    athletes: "utovere",
    stores: "utsalgssteder",
    materials: "materialer",
    faq: "faq",
    jobs: "jobs",
    contact: "kontakt",
    calendar: "aktivitetskalender",
    responsibility: "vart-ansvar",
    forHumans: "for-mennesker",
    forDogs: "for-hunder",
    christmasCalendar: "julegaveguide",
  },

  marketId: process.env.GATSBY_NO_MARKET_ID,

  countryCode: "NO",
  availableShippingCountries: ["NO"],
  shippingCost: "99",
  freeShippingMinimum: "500",

  cartUrl: "https://www.nonstopdogwear.com/no/",
  returnUrl: "https://www.nonstopdogwear.com/no/produkter/",
  privacyUrl: "https://www.nonstopdogwear.com/no/personvernerklaering/",
  termsUrl: "https://www.nonstopdogwear.com/no/kjopsbetingelser/",
  priceField: "norway_nok",
  currency: "NOK",
} as Language
