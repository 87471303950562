import React from "react"
import styled from "styled-components"
import { Link } from "gatsby"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faCaretLeft } from "@fortawesome/free-solid-svg-icons"
import { DirectusSubMenuLink } from "../MainMenuLink"
export default ({
  links,
  isOpen,
  closeMenu,
  closeSubMenu,
  openSubMenuById,
}: {
  links: DirectusSubMenuLink[]
  isOpen: boolean
  closeMenu: () => void
  closeSubMenu: () => void
  openSubMenuById: (id: string) => void
}) => {
  const subLinks = links
    .sort((a, b) => a.position_header - b.position_header)
    .filter(
      (link) =>
        !link.parent_submenu || //Does not have a parent submenu
        !links.some((l) => l.id === link.parent_submenu?.id) // or if it has; it's not a sibling
    )
    .map((link) =>
      link.url ? (
        <li key={link.id}>
          <MobileLink
            to={link.url}
            onClick={() => {
              closeSubMenu()
              closeMenu()
            }}
          >
            {link.name}
          </MobileLink>
        </li>
      ) : (
        <li key={link.id}>
          <MobileLink
            as="span"
            onClick={() => openSubMenuById(link.id)}
          >
            {link.name} ›
          </MobileLink>
        </li>
      )
    )

  return (
    <MobileMenu $isOpen={isOpen}>
      <ul>
        <li key={"back"}>
          <MobileLink
            as="span"
            onClick={() => closeSubMenu()}
          >
            <FontAwesomeIcon
              icon={faCaretLeft}
              size="1x"
              color="#8e8e8e"
            />
            Back
          </MobileLink>
        </li>
        {subLinks}
      </ul>
    </MobileMenu>
  )
}

export const MobileMenu = styled.div<{
  $topLevel?: boolean | undefined
  $isOpen: boolean
}>`
  background: #eee;
  height: calc(100vh - 162px);
  width: 100%;
  scrollbar-width: none;
  position: absolute;
  top: 135px;
  left: ${({ $isOpen }) => ($isOpen ? 0 : "-100%")};
  z-index: 10;
  font-size: ${({ theme }) => theme.fontBase};
  padding: 0;

  margin: 0;
  overflow-y: auto;

  transition: 0.2s all ease-out;

  & li {
    padding: 18px 20px;
    border-bottom: solid 1px #fff;
    display: inline-block;
    width: 100%;
    box-sizing: border-box;
  }
  & li:first-child {
    background-color: ${({ $topLevel }) => ($topLevel ? "inherit" : "#fff")};
    & span {
      text-transform: ${({ $topLevel }) =>
        $topLevel ? "uppercase" : "initial"};
    }
    & svg {
      margin-right: 40px;
    }
  }

  @media (min-width: ${({ theme }) => theme.screens.xl}) {
    display: none;
  }
`

export const MobileLink = styled(Link)`
  text-decoration: none;
  text-transform: uppercase;
  color: #232323;
  letter-spacing: 0.15rem;
  width: 100%;
  box-sizing: border-box;
  display: inline-block;
`
