import { Language } from "./type"

export default {
  name: "Svenska",
  iconName: "se",
  subFolder: `se`,

  languageCode: `sv`,
  hreflang: "sv",

  mapDefaultCenterLat: 59.33258,
  mapDefaultCenterLng: 18.0649,

  slugs: {
    about: "om",
    products: "produkter",
    product: "produkt",
    magazine: "magasin",
    athletes: "atleter",
    stores: "butiker",
    materials: "material",
    faq: "faq",
    jobs: "jobb",
    contact: "kontakt",
    calendar: "aktivitetskalender",
    responsibility: "vart-ansvar",
    forHumans: "for-manniskor",
    forDogs: "for-hundar",
    christmasCalendar: "julklappsguide",
  },

  marketId: process.env.GATSBY_SE_MARKET_ID,

  countryCode: "SE",
  availableShippingCountries: ["SE"],
  shippingCost: "149",
  freeShippingMinimum: "600",

  cartUrl: "https://www.nonstopdogwear.com/se/",
  returnUrl: "https://www.nonstopdogwear.com/se/produkter/",
  privacyUrl: "https://www.nonstopdogwear.com/se/integritetsforklaring/",
  termsUrl: "https://www.nonstopdogwear.com/se/kopvillkor/",
  priceField: "sweden_sek",
  currency: "SEK",
} as Language
