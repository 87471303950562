import React, { useState } from "react"
import { DirectusMenuLink, DirectusSubMenuLink } from "../MainMenuLink"

import SubMenuMobile, { MobileMenu, MobileLink } from "./sub-menu-mobile"

export const MainMenuMobile = ({
  links,
  isOpen,
  closeMenu,
}: {
  links: DirectusMenuLink[]
  isOpen: boolean
  closeMenu: () => void
}) => {
  const [subMenuIsOpen, setIsOpen] = useState<string>("")

  const openSubMenuById = (id: string) => {
    setIsOpen(id)
  }

  const formattedLinks = links
    .filter((link) => link.display !== "desktop")
    .sort((a, b) => a.position - b.position)
    .map((link) => {
      const linkWithSubFolder = { ...link, key: link.id }
      if (link.url) {
        linkWithSubFolder.url = `${link.url}`
      }
      linkWithSubFolder.main_submenu_links =
        linkWithSubFolder.main_submenu_links
          .filter((subLink) => subLink.display !== "desktop")
          .map((subLink) => {
            const subLinkWithSubFolder = { ...subLink }
            if (subLink.url) {
              subLinkWithSubFolder.url = `/${subLink.url}`
            }
            return subLinkWithSubFolder
          })
      return linkWithSubFolder
    })
  const topLevelLinks = formattedLinks.map((link) => {
    if (link.main_submenu_links && link.main_submenu_links.length > 0) {
      return (
        <li key={link.id}>
          <MobileLink
            as="span"
            onClick={() => openSubMenuById(link.id)}
          >
            {link.name} ›
          </MobileLink>
        </li>
      )
    }
    if (link.url) {
      return (
        <li key={link.id}>
          <MobileLink
            onClick={() => closeMenu()}
            to={link.url}
          >
            {link.name}
          </MobileLink>
        </li>
      )
    }
    return null
  })

  const subMenus = formattedLinks
    .filter(
      (link) => link.main_submenu_links && link.main_submenu_links.length > 0
    )
    .map((link) => (
      <SubMenuMobile
        key={link.id}
        links={link.main_submenu_links}
        isOpen={subMenuIsOpen === link.id}
        closeMenu={closeMenu}
        closeSubMenu={() => openSubMenuById("")}
        openSubMenuById={(id) => openSubMenuById(id)}
      />
    ))

  const subMenusLvl2 = [] as { id: string; subMenus: DirectusSubMenuLink[] }[]
  for (const topLevelMenu of formattedLinks) {
    if (
      topLevelMenu.main_submenu_links &&
      topLevelMenu.main_submenu_links.length > 0
    ) {
      for (const subMenu of topLevelMenu.main_submenu_links) {
        if (subMenu.parent_submenu) {
          const subMenuLvl2Index = subMenusLvl2.findIndex(
            (sm) => sm.id === subMenu.parent_submenu.id
          )
          if (subMenuLvl2Index > -1) {
            subMenusLvl2[subMenuLvl2Index].subMenus.push(subMenu)
          } else {
            subMenusLvl2.push({
              id: subMenu.parent_submenu.id,
              subMenus: [subMenu],
            })
          }
        }
      }
    }
  }

  const subMenusLvl2Components = subMenusLvl2.map((link) => (
    <SubMenuMobile
      key={link.id}
      links={link.subMenus}
      isOpen={subMenuIsOpen === link.id}
      closeMenu={closeMenu}
      closeSubMenu={() => openSubMenuById("")}
      openSubMenuById={(id) => openSubMenuById(id)}
    />
  ))

  return (
    <>
      <MobileMenu
        $topLevel
        $isOpen={isOpen}
      >
        <ul>{topLevelLinks}</ul>
      </MobileMenu>
      {subMenus}
      {subMenusLvl2Components}
    </>
  )
}
