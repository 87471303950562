import { Market } from "./type"

export default {
  name: "Finland",
  marketId: process.env.GATSBY_FI_MARKET_ID,
  countryCode: "FI",
  availableShippingCountries: ["FI"],
  shippingCost: "14.9",
  freeShippingMinimum: "60",
  cartUrl: "https://www.nonstopdogwear.com/fi/",
  returnUrl: "https://www.nonstopdogwear.com/fi/tuotteet/",
  privacyUrl: "https://www.nonstopdogwear.com/fi/yksityisyydensuojakayntanto/",
  termsUrl: "https://www.nonstopdogwear.com/fi/ostoehdot/",

  priceField: "finland_eur",
  currency: "EUR",
} as Market
