import { Market } from "./type"

export default {
  name: "Danmark",
  marketId: process.env.GATSBY_DK_MARKET_ID,
  countryCode: "DK",
  availableShippingCountries: ["DK", "GL"],
  shippingCost: "129",
  freeShippingMinimum: "450",
  cartUrl: "https://www.nonstopdogwear.com/en/",
  returnUrl: "https://www.nonstopdogwear.com/en/products/",
  privacyUrl: "https://www.nonstopdogwear.com/en/privacy/",
  termsUrl: "https://www.nonstopdogwear.com/en/terms/",
  priceField: "denmark_dkk",
  currency: "DKK",
} as Market
