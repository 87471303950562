import { markets } from "../../config/markets"

import axios from "axios"
import { Market } from "../../config/markets/type"
import countries from "../../config/countries"

type IPAPIResponse = {
  query: string
  status: string
  continent: string
  continentCode: string
  country: string
  countryCode: string
  region: string
  regionName: string
  city: string
  district: string
  zip: string
  lat: number
  lon: number
  timezone: string
  offset: number
  currency: string
  isp: string
  org: string
  as: string
  asname: string
  mobile: boolean
  proxy: boolean
  hosting: boolean
}

export async function detectUserCountryCode(): Promise<string> {
  const countryCode = sessionStorage.getItem("countryCode")
  if (countryCode && countryCode !== "undefined") {
    return countryCode
  }

  try {
    const response = await axios.get<IPAPIResponse>(
      `https://pro.ip-api.com/json?key=${process.env.GATSBY_IP_API_KEY}`
    )
    const { data } = response
    if (data.countryCode) {
      sessionStorage.setItem("countryCode", data.countryCode)
    }
    return data.countryCode
  } catch (err) {
    console.error(err)
    sessionStorage.setItem("countryCode", "NO")
    return "NO"
  }
}

export function getFullCountryName(code: string): string {
  const country = countries.find(country => country.code === code)
  if (country) {
    return country.name
  }
  return code
}
