import React from "react"
import { AnimatePresence, motion } from "framer-motion"

import {
  LineItem,
  LineItemImage,
  LineItemName,
  LineItemQuantity,
  LineItemAmount,
  LineItemRemoveLink,
} from "@commercelayer/react-components"
import styled from "styled-components"

export default () => {
  return (
    <>
      <AnimatePresence>
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          transition={{ duration: 0.2 }}
        >
          <StlyedLineItem>
            <Item>
              <ProductImage width={100} />
              <div>
                <StyledLineItemName />

                <StyledLineItemQuantity />
              </div>
              <div>
                <TotalAmount />
                <RemoveButton label="×" />
              </div>
            </Item>
          </StlyedLineItem>
        </motion.div>
      </AnimatePresence>
    </>
  )
}

const StlyedLineItem = styled(LineItem)`
  margin-bottom: 40px;
`

const Item = styled.div`
  display: grid;
  grid-template-columns: 100px 1fr 70px;
  grid-column-gap: 15px;
  font-size: 14px;
  color: #242424;
`

const ProductImage = styled(LineItemImage)`
  grid-row: span 2;
`

const TotalAmount = styled(LineItemAmount)`
  text-align: right;
  display: flex;
  padding-bottom: 1rem;
`

const RemoveButton = styled(LineItemRemoveLink)`
  color: #242424;
  font-size: 3rem;
  background: transparent;
  border: none;
  text-align: right;
  text-decoration: none;
  &:hover {
    cursor: pointer;
    color: ${({ theme }) => theme.primaryColor};
  }
  &:disabled {
    color: #acacac;
  }
`

const StyledLineItemName = styled(LineItemName)`
  padding-bottom: 5px;
`

const StyledLineItemQuantity = styled(LineItemQuantity)`
  padding-top: 5px;
`
