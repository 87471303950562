import React from "react"
import styled from "styled-components"

import MainMenu from "./navigation/MainMenu"
import { MobileOnly, TabletOnly, DesktopOnly } from "./styles"

export default function Header({
  activity,
}: {
  activity: Queries.ActivityQuery["strapiActivity"]
}) {
  return (
    <>
      <MobileOnly>
        <StyledHeader>
          <MainMenu activity={activity} />
        </StyledHeader>
      </MobileOnly>
      <TabletOnly>
        <StyledHeader>
          <MainMenu activity={activity} />
        </StyledHeader>
      </TabletOnly>
      <DesktopOnly>
        <header>
          <MainMenu activity={activity} />
        </header>
      </DesktopOnly>
    </>
  )
}

const StyledHeader = styled.header`
  height: 135px;
`
