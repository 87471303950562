import React, { useState, useEffect, useContext, useMemo } from "react"
import {
  trackAddToCart,
  trackWithKlaviyo,
  trackWithPinterest,
} from "../services/analyticsTrackers/addToCart"
import { Market } from "../../config/markets/type"
import { markets } from "../../config/markets"
import CartConfig from "../../config/cart/cart"
import {
  fetchOrder,
  getOrder,
  OrderWithData,
  updateOrder,
  updateTrafficSources,
  getRecommendedSize,
} from "../services/commerce-layer/order"
import { Language } from "../../config/languages/type"
import { isEqual } from "lodash-es"
import {
  createLineItem,
  deleteLineItem,
  updateLineItem,
} from "../services/commerce-layer/line-item"
import { getOrderCookie } from "../services/commerce-layer/cookies"
import { debounce } from "lodash-es"
import { detectUserCountryCode } from "../services/geoDetection"
import { formatCurrency } from "../helpers"
import { wait } from "../utils/delay"

type CartValues = {
  setCartVisible: React.Dispatch<React.SetStateAction<boolean>>
  isCartVisible: boolean
  addToCart: ({
    name,
    sku,
    imageUrl,
  }: {
    name: string
    sku: string
    imageUrl?: string
  }) => void
  order: OrderWithData | undefined
  removeFromCart: (sku: string) => Promise<void>
  updateQuantity: ({
    sku,
    quantity,
  }: {
    sku: string
    quantity: number
  }) => Promise<void>
  shippingCountry: string
  setShippingCountry: React.Dispatch<React.SetStateAction<string>>
  market: Market
  setMarket: React.Dispatch<React.SetStateAction<Market>>
}

const CartContext = React.createContext<CartValues | undefined>(undefined)

export default ({ children }: { children: React.ReactNode }) => {
  const [order, setOrder] = useState<OrderWithData | undefined>()
  const [isCartVisible, setCartVisible] = useState(false)
  const [market, setMarket] = useState<Market>(markets.NO)
  const [shippingCountry, setShippingCountry] = useState<string>("NO")

  useEffect(() => {
    const newMarket = Object.values(markets).find((m) =>
      m.availableShippingCountries.includes(shippingCountry)
    )
    if (newMarket) {
      setMarket(newMarket)
    }
  }, [shippingCountry])

  //Change order language code on language code change and destination URL
  useEffect(() => {
    let isCancelled = false

    async function refreshOrderFromAPI() {
      let orderId = getOrderCookie({
        marketId: market?.marketId || "",
        countryCode: shippingCountry,
      })
      if (!orderId) {
        setOrder(undefined)
      } else {
        const apiOrder = await updateOrder({
          marketId: market?.marketId || "",
          orderId,
          attributes: {
            shipping_country_code_lock: shippingCountry,
            language_code: CartConfig.languageCode,
            cart_url: CartConfig.cartUrl,
            return_url: CartConfig.returnUrl,
            privacy_url: CartConfig.privacyUrl,
            terms_url: CartConfig.termsUrl,
            metadata: {
              recommendedSize: [getRecommendedSize()],
            },
          },
        })

        if (!isCancelled) {
          setOrder(apiOrder)
        }
      }
    }
    refreshOrderFromAPI()
    return () => {
      isCancelled = true
    }
  }, [market, CartConfig.languageCode])

  useEffect(() => {
    if (isCartVisible) {
      document.body.style.overflow = "hidden"
    } else {
      document.body.style.overflow = "auto"
    }
  }, [isCartVisible])

  useEffect(() => {
    let isCancelled = false

    async function initializeShippingCountry() {
      let userCurrentCountry = sessionStorage.getItem("countryCode")
      if (!userCurrentCountry) {
        userCurrentCountry = await detectUserCountryCode()
      }
      const userPreferredCountry = sessionStorage.getItem(
        "shippingCountryManuallyChanged"
      )

      if (userPreferredCountry) {
        if (!isCancelled) {
          setShippingCountry(userPreferredCountry)
        }
      } else {
        if (!isCancelled) {
          if (userCurrentCountry !== "RU") {
            setShippingCountry(userCurrentCountry)
          }
        }
      }
    }
    initializeShippingCountry()

    return () => {
      isCancelled = true
    }
  }, [])

  useEffect(() => {
    let isCancelled = false

    async function refreshOrderFromAPI() {
      if (
        order ||
        getOrderCookie({
          marketId: market?.marketId || "",
          countryCode: shippingCountry,
        })
      ) {
        const apiOrder = await getOrder({
          marketId: market.marketId,
          shippingCountry,
          locale: CartConfig,
        })
        if (!isEqual(order, apiOrder) && !isCancelled) {
          setOrder(apiOrder)
        }
      }
    }
    refreshOrderFromAPI()
    return () => {
      isCancelled = true
    }
  }, [])

  function findLineItemIndex(sku: string) {
    if (!order) {
      return -1
    }
    return order.lineItems.findIndex((li) => li.attributes.sku_code === sku)
  }

  async function addToCart({
    name,
    sku,
    imageUrl,
  }: {
    name?: string
    sku: string
    imageUrl?: string
  }) {
    let cart: OrderWithData | undefined = undefined
    if (order) {
      cart = { ...order }
    } else {
      cart = await getOrder({
        marketId: market.marketId,
        shippingCountry,
        locale: CartConfig,
      })
    }
    if (!cart.lineItems) {
      cart.lineItems = []
    }

    const lineItemInOrderIndex = findLineItemIndex(sku)
    if (lineItemInOrderIndex > -1) {
      cart.lineItems[lineItemInOrderIndex].attributes.quantity =
        cart.lineItems[lineItemInOrderIndex].attributes.quantity + 1
    } else {
      cart.lineItems.push({
        id: "tmpSku",
        type: "line_items",
        attributes: {
          sku_code: sku,
          quantity: 1,
          unit_amount_cents: -1,
          name: name || null,
          image_url: imageUrl || null,
          item_type: "skus",
        },
      })
      setOrder(cart)
      setCartVisible(true)

      let orderFromApi = await createLineItem({
        marketId: market.marketId,
        orderId: cart.id,
        sku: sku,
      })
      setOrder(orderFromApi)

      if (orderFromApi.attributes.coupon_code) {
        await wait(1)
        orderFromApi = await fetchOrder({
          marketId: market.marketId || "",
          orderId: cart.id,
        })
      }

      setOrder(orderFromApi)
      updateTrafficSources({ marketId: market.marketId, orderId: cart.id })
      trackAddToCart(name || "", sku)
      if ("_learnq" in window) {
        trackWithKlaviyo(sku, orderFromApi)
      }
      trackWithPinterest(sku, orderFromApi)
    }
  }

  const debouncedUpdateLineItem = useMemo(
    () =>
      debounce(
        async (e: {
          id: string
          sku: string
          marketId?: string
          orderId: string
          quantity: number
        }) => {
          const updatedLineItem = await updateLineItem(e)
          setOrder(updatedLineItem)
          if (updatedLineItem.attributes.coupon_code) {
            await wait(1)
            const orderFromApi = await fetchOrder({
              marketId: market.marketId || "",
              orderId: updatedLineItem.id,
            })
            setOrder(orderFromApi)
          }
        },
        500
      ),
    []
  )

  async function updateQuantity({
    sku,
    quantity,
  }: {
    sku: string
    quantity: number
  }) {
    let cart: OrderWithData | undefined = undefined
    if (order) {
      cart = { ...order }
    } else {
      cart = await getOrder({
        marketId: market.marketId,
        shippingCountry,
        locale: CartConfig,
      })
    }
    if (!cart.lineItems) {
      cart.lineItems = []
    }
    const lineItemInOrderIndex = findLineItemIndex(sku)

    if (lineItemInOrderIndex > -1) {
      cart.lineItems[lineItemInOrderIndex].attributes.quantity = quantity
      setOrder(cart)
      await debouncedUpdateLineItem({
        marketId: market.marketId,
        id: cart.lineItems[lineItemInOrderIndex].id,
        sku,
        orderId: cart.id,
        quantity,
      })
    } else {
      setOrder(cart)
    }
  }

  async function removeFromCart(sku: string) {
    let cart: OrderWithData | undefined = undefined
    if (order) {
      cart = { ...order }
    } else {
      cart = await getOrder({
        marketId: market.marketId,
        shippingCountry,
        locale: CartConfig,
      })
    }
    if (!cart.lineItems) {
      cart.lineItems = []
    }
    const lineItemInOrderIndex = findLineItemIndex(sku)

    if (lineItemInOrderIndex > -1) {
      const deletedItem = cart.lineItems.splice(lineItemInOrderIndex, 1)[0]
      setOrder(cart)
      const orderFromApi = await deleteLineItem({
        marketId: market.marketId,
        id: deletedItem.id,
        orderId: cart.id,
      })
      setOrder(orderFromApi)
      if (orderFromApi.attributes.coupon_code) {
        await wait(1)
        const updatedWithCoupon = await fetchOrder({
          marketId: market.marketId || "",
          orderId: orderFromApi.id,
        })
        setOrder(updatedWithCoupon)
      }
    } else {
      setOrder(cart)
    }
  }

  return (
    <CartContext.Provider
      value={{
        setCartVisible,
        isCartVisible,
        addToCart,
        order,
        removeFromCart,
        updateQuantity,
        shippingCountry,
        setShippingCountry,
        market,
        setMarket,
      }}
    >
      {children}
    </CartContext.Provider>
  )
}

export function useCart() {
  const context = useContext(CartContext)

  if (context === undefined) {
    throw new Error("useCart must be used within a CartProvider")
  }

  return context
}
