import { getSubFolderFromCartUrl } from "../../utils/getSubFolder"
import { getProductNameFromSKUName } from "../../utils/naming"
import { getProductUrl } from "../../utils/productUrl"
import { OrderWithData } from "../commerce-layer/order"

export const trackAddToCart = (name: string, sku: string) => {
  trackWithGoogle(name, sku)
  trackWithFacebook(name, sku)
}

const trackWithGoogle = (name: string, sku: string) => {
  if (window && window.dataLayer) {
    window.dataLayer.push({
      event: "EEaddToCart",
      ecommerce: {
        add: {
          products: [
            {
              id: sku,
              name,
              quantity: 1,
            },
          ],
        },
      },
    })
  }
}

const trackWithFacebook = (name: string, sku: string) => {
  if (window && window.dataLayer) {
    if (typeof fbq !== "undefined") {
      fbq("track", "AddToCart", {
        content_ids: [sku],
        content_name: name,
        content_type: "product",
      })
    }
  }
}

export const trackWithPinterest = (sku: string, order: OrderWithData) => {
  if ("pintrk" in window) {
    const items = order.lineItems
    const addedItem = items.find(item => item.attributes.sku_code === sku)
    if (!addedItem) {
      return
    }

    pintrk("track", "AddToCart", {
      value: addedItem.attributes.unit_amount_cents / 100,
      order_quantity: 1,
      currency: order.attributes.currency_code,
      order_id: order.attributes.number,
      product_id: addedItem.attributes.sku_code,
      product_name: addedItem.attributes.name,
      product_price: addedItem.attributes.unit_amount_cents / 100,
    })
  }
}

export const trackWithKlaviyo = (sku: string, order: OrderWithData) => {
  if ("_learnq" in window) {
    var _learnq = window._learnq || []
    const items = order.lineItems
    const addedItem = items.find(item => item.attributes.sku_code === sku)
    if (!addedItem) {
      return
    }
    const subFolder = getSubFolderFromCartUrl(order.attributes.cart_url)

    const klaviyoAddedItem = {
      AddedItemProductName: addedItem.attributes.name,
      AddedItemProductID: sku,
      AddedItemSKU: sku,
      AddedItemImageURL: addedItem.attributes.image_url,
      AddedItemURL: getProductUrl(
        subFolder,
        getProductNameFromSKUName(addedItem.attributes.name)
      ),
      AddedItemPrice: addedItem.attributes.unit_amount_cents / 100,
      AddedItemQuantity: 1,
    }

    const itemNames = items.map(item => item.attributes.name)
    const klaviyoItems = items.map(item => ({
      ProductID: item.attributes.sku_code,
      SKU: item.attributes.sku_code,
      ProductName: item.attributes.namme,
      Quantity: item.attributes.quantity,
      ItemPrice: item.attributes.unit_amount_cents / 100,
      RowTotal:
        item.attributes.unit_amount_cents / 100 / item.attributes.quantity,
      ProductURL: getProductUrl(
        subFolder,
        getProductNameFromSKUName(item.attributes.name)
      ),
      ImageURL: item.attributes.image_url,
    }))

    _learnq.push([
      "track",
      "Added to Cart",
      {
        $value: order.attributes.total_amount_with_taxes_cents / 100,
        ...klaviyoAddedItem,
        ItemNames: itemNames,
        CheckoutURL: order.attributes.checkout_url,
        Items: klaviyoItems,
      },
    ])
  }
}
