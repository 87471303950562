import React, { useState, useEffect } from "react"
import { Link, navigate } from "gatsby"
import styled from "styled-components"
import { useStaticQuery, graphql } from "gatsby"

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
  faBars,
  faTimes,
  faSignInAlt,
  faUser,
} from "@fortawesome/free-solid-svg-icons"
import Logo from "../../images/logo-police.png"
import { MainMenuLinks } from "./MainMenuLinks"
import { MainMenuMobile } from "./mobile/main-menu-mobile"

import { useCart } from "../../context/cartContext"

import Container from "../Container"

import CartIconEmpty from "../../images/add-to-cart-icon.svg"
import CartIconFull from "../../images/cart-icon-full.svg"
import {
  LineItemsContainer,
  LineItemsCount,
} from "@commercelayer/react-components"

import { isCustomerLoggedIn } from "../../services/commerceLayer/customerAuth"
import { SubMenu } from "./user/SubMenu"

export default ({
  activity,
}: {
  activity: Queries.ActivityQuery["strapiActivity"]
}) => {
  const headerBgColor = activity ? activity?.primary_color : ""
  const headerTextColor = activity ? activity?.primary_text_color : ""
  const headerLinkHoverColor = activity ? activity?.link_text_hover_color : ""
  const { setCartVisible, order } = useCart()
  const [isMobileMenuOpen, setMobileMenuOpen] = useState(false)
  const [isOpen, setOpen] = useState(false)

  useEffect(() => {
    document.body.classList.remove("menu--open")
  }, [])

  useEffect(() => {
    if (isMobileMenuOpen) {
      document.body.classList.add("menu--open")
    } else {
      document.body.classList.remove("menu--open")
    }
  }, [isMobileMenuOpen])

  const handleClick = () => {
    setMobileMenuOpen((isOpen) => !isOpen)
  }

  const closeMenu = () => {
    setMobileMenuOpen(false)
  }

  const CartIcon =
    order && order.attributes.skus_count > 0 ? CartIconFull : CartIconEmpty
  const { allStrapiMainMenuLink }: Queries.mainMenuLinksQuery = useStaticQuery(
    graphql`
      query mainMenuLinks {
        allStrapiMainMenuLink(
          sort: { fields: position_header }
          filter: { display_in_header: { eq: true } }
        ) {
          nodes {
            display
            display_in_footer
            display_in_header
            dropdown
            id
            name
            strapi_id
            style
            url
            login_required
            position_header
            main_submenu_links {
              strapi_id
              id
              url
              position_header
              display_in_footer
              display
              display_in_header
              name
            }
          }
        }
      }
    `
  )

  if (!allStrapiMainMenuLink) {
    throw new Error(`No main menu links found`)
  }
  const links = allStrapiMainMenuLink.nodes.map((menuLink) => {
    if (!menuLink) {
      return null
    }

    const subMenuLinks = menuLink?.main_submenu_links?.filter(
      (subMenuLink) => !!subMenuLink?.display_in_header
    )
    return { ...menuLink, main_submenu_links: subMenuLinks }
  })

  return (
    <>
      <Wrapper $bgColor={headerBgColor}>
        <Nav>
          <div>
            <StyledLink to={`/`}>
              <img
                src={Logo}
                alt="Non-stop defense Logo"
                width="200"
              />
            </StyledLink>
          </div>
          <MainMenuLinks
            linkTextHoveColor={headerLinkHoverColor || ""}
            textColor={headerTextColor || ""}
            links={links}
          />

          <Icons>
            {isCustomerLoggedIn() ? (
              <>
                <UserMenuButton onMouseEnter={() => setOpen(true)}>
                  <FontAwesomeIcon
                    icon={faUser}
                    size="1x"
                    color="#ffffff"
                  />
                </UserMenuButton>{" "}
                {isOpen && (
                  <SubMenu onHover={(value: boolean) => setOpen(value)} />
                )}
              </>
            ) : (
              <LoginButton onClick={() => navigate("/login")}>
                <IconAndTextContainer>
                  <FontAwesomeIcon
                    icon={faSignInAlt}
                    size="xl"
                    color="#ffffff"
                  />{" "}
                  <LoginText>Login</LoginText>
                </IconAndTextContainer>
              </LoginButton>
            )}

            <Icon onClick={() => setCartVisible((value) => !value)}>
              <img
                src={CartIcon}
                alt="Cart"
              />
              <LineItemsContainer>
                <CartIconQuantity style={{ color: "#ffffff" }} />
              </LineItemsContainer>
            </Icon>
          </Icons>
        </Nav>
        <NavMobile>
          <MenuButton onClick={() => handleClick()}>
            {isMobileMenuOpen ? (
              <FontAwesomeIcon
                icon={faTimes}
                size="2x"
                color="#ffffff"
              />
            ) : (
              <FontAwesomeIcon
                icon={faBars}
                size="2x"
                color="#ffffff"
              />
            )}
          </MenuButton>
        </NavMobile>
      </Wrapper>
      <MainMenuMobile
        links={links}
        isOpen={isMobileMenuOpen}
        closeMenu={() => closeMenu()}
      />
    </>
  )
}

const Wrapper = styled(Container)<{ $bgColor?: string | null }>`
  background-color: ${({ $bgColor, theme }) =>
    $bgColor || theme.activityColors.primary_color};
  height: 100%;
  box-sizing: border-box;
  position: relative;
  @media (min-width: ${({ theme }) => theme.screens.xl}) {
    padding-top: 21px;
    padding-bottom: 12px;
  }
`

const Nav = styled.nav`
  text-transform: uppercase;
  display: flex;
  justify-content: space-between;
  align-items: center;
  @media (min-width: ${({ theme }) => theme.screens.xl}) {
    position: static;
    justify-content: flex-start;
  }
`

const NavMobile = styled.nav`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 20px;

  @media (min-width: ${({ theme }) => theme.screens.xl}) {
    display: none;
  }
  @media (max-width: ${({ theme }) => theme.screens.sm}) {
    position: relative;
  }
`
const Icons = styled.div`
  margin-left: auto;
  display: flex;
  gap: 20px;
  position: relative;
  @media only screen and (max-width: 1400px) {
    right: 100px;
  }

  @media only screen and (max-width: ${({ theme }) => theme.screens.sm}) {
    right: 20px;
  }
`

const Icon = styled.button`
  position: relative;

  height: 21px;
  width: 16px;
  display: inline-block;
  color: #fff;
  background-color: transparent;
  border: 0;
  cursor: pointer;
  padding: 0;

  & > img {
    position: absolute;
    left: 0;
    top: 0;
    width: 16px;
    height: 21px;
  }

  &:hover {
    background-color: transparent;
  }
`

const CartIconQuantity = styled(LineItemsCount)<{ isFilled: boolean }>`
  font-size: 0.5rem;
  font-weight: 600;
  color: ${({ theme }) => theme.primaryColor};
`

const MenuButton = styled.button`
  background-color: transparent;
  border: none;
  cursor: pointer;
  align-self: center;
  position: absolute;
  padding: 0;
  bottom: 5px;
  display: block;
  @media (min-width: ${({ theme }) => theme.screens.xl}) {
    display: none;
  }

  @media (max-width: ${({ theme }) => theme.screens.sm}) {
    position: relative;
  }
`

const LoginButton = styled(MenuButton)`
  position: relative;
  bottom: 0;
  right: 0;
  top: 3px;
  border: 0px;
  box-shadow: none;
  cursor: pointer;
  &:hover {
    background: none;
  }
  @media (min-width: ${({ theme }) => theme.screens.xl}) {
    display: inline-block;
  }
`
const IconAndTextContainer = styled.div`
  display: flex;
  align-items: center;
`

const LoginText = styled.span`
  margin-left: 8px;
  font-size: 16px;
  color: #ffffff;
`

const UserMenuButton = styled(LoginButton)``

export const NavLinkMobile = styled(Link)`
  font-size: 2rem;
  letter-spacing: 0.05rem;
  color: #fff;
  text-decoration: none;
  padding: 3rem;
  display: inline-block;
  width: 100%;
  box-sizing: border-box;
  max-width: 100%;
`

const StyledLink = styled(Link)`
  &:hover {
    border-bottom: none;
  }
`
