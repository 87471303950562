import { Market } from "./type"

export default {
  name: "United Kingdom",
  marketId: process.env.GATSBY_UK_MARKET_ID,
  countryCode: "",
  availableShippingCountries: ["GB", "GG", "GI", "IM", "JE"],
  shippingCost: "14.9",
  freeShippingMinimum: "50",

  cartUrl: "https://www.nonstopdogwear.com/en/",
  returnUrl: "https://www.nonstopdogwear.com/en/products/",
  privacyUrl: "https://www.nonstopdogwear.com/en/privacy/",
  termsUrl: "https://www.nonstopdogwear.com/en/terms/",
  priceField: "united_kingdom_gbp",
  currency: "GBP",
} as Market
