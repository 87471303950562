import React, { useState } from "react"
import styled from "styled-components"
import { Link } from "gatsby"
import { SubMenu } from "./subMenu/SubMenu"
import { AnimatePresence } from "framer-motion"
import { FixedObject } from "gatsby-image"

type StrapiSubMenu = {
  strapi_id: number
  display_in_footer: boolean
  display_in_header: boolean
  name: string
  url: string | null
  display: "both" | "mobile" | "desktop"
}

export type StrapiMenuLink = {
  node: {
    strapi_id: number
    display_in_footer: boolean
    display_in_header: boolean
    name: string
    url: string | null
    style: "columns" | "grid" | "angle" | null
    display: "both" | "mobile" | "desktop"
    main_submenu_links: StrapiSubMenu[]
  }
}

export type DirectusMenuLink = {
  id: string
  name: string
  url: string | null
  position: number
  style: "columns" | "grid" | "angle" | null
  display: "both" | "mobile" | "desktop"
  main_submenu_links: DirectusSubMenuLink[]
}

export type DirectusSubMenuLink = {
  id: string
  name: string
  url?: string | null
  position: number
  display: "both" | "mobile" | "desktop"

  image: {
    localFile: {
      childImageSharp: {
        fixed: FixedObject
      }
    }
  } | null
  parent_submenu: {
    id: DirectusSubMenuLink["id"]
  }
}

export const MainMenuLink = ({
  link,
  textColor,
  linkTextHoveColor,
}: {
  link: unknown
  textColor?: string
  linkTextHoveColor?: string
}) => {
  const [isOpen, setOpen] = useState(false)

  if (link.url) {
    return (
      <AnimatePresence>
        <li
          key={link.id}
          onMouseEnter={() => setOpen(true)}
          onMouseLeave={() => setOpen(false)}
        >
          <NavLink
            $textcolor={textColor}
            $linktexthovercolor={linkTextHoveColor}
            to={link.url}
          >
            {link.name}
          </NavLink>
        </li>
        {isOpen && (
          <SubMenu
            onHover={(value: boolean) => setOpen(value)}
            link={link}
          />
        )}
      </AnimatePresence>
    )
  }
  return (
    <AnimatePresence>
      <li
        key={link.id}
        onMouseEnter={() => setOpen(true)}
        onMouseLeave={() => setOpen(false)}
      >
        <NavLink
          $textcolor={textColor}
          $linktexthovercolor={linkTextHoveColor}
          as="span"
        >
          {link.name}
        </NavLink>
      </li>
      {isOpen && (
        <SubMenu
          onHover={(value: boolean) => setOpen(value)}
          link={link}
        />
      )}
    </AnimatePresence>
  )
}

const NavLink = styled(Link)<{
  $textcolor?: string
  $linktexthovercolor?: string
}>`
  color: ${({ $textcolor, theme }) =>
    $textcolor || theme.activityColors.primary_text_color};
  list-style-type: none;
  text-decoration: none;
  font-size: ${({ theme }) => theme.fontBase};
  padding-bottom: 1.6rem;
  letter-spacing: 0.1rem;
  transform: translateZ(0);
  backface-visibility: hidden;
  position: relative;
  overflow: hidden;

  &:before {
    content: "";
    position: absolute;
    left: 0;
    right: 100%;
    bottom: 0;
    height: 4px;
    transition-property: right;
    transition-duration: 0.3s;
    transition-timing-function: ease-in-out;
  }

  &:hover {
    color: ${({ $linktexthovercolor, theme }) =>
      $linktexthovercolor || theme.activityColors.link_text_hover_color};
    border-bottom: 2px solid
      ${({ $linktexthovercolor, theme }) =>
        $linktexthovercolor || theme.activityColors.link_text_hover_color};
  }

  &:hover:before {
    right: 0;
  }
`
