import React from "react"
import Footer from "../Footer"
import Theme from "../../styles/theme"
import AppProviders from "../../context/index"
import CommerceLayerLayout from "../commercelayer/CommerceLayerLayout"
import { ToastContainer } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"

export type LayoutContextType = {
  id: number
  name?: string
  pageType?: string
  data?: Queries.ActivityQuery["strapiActivity"]
}

export default function Layout({
  children,
  pageContext,
  location,
}: {
  children: React.ReactNode
  pageContext: LayoutContextType
  location: Location
}) {
  return (
    <AppProviders location={location}>
      <Theme />
      <CommerceLayerLayout pageContext={pageContext}>
        {children}
      </CommerceLayerLayout>
      <Footer />
      <ToastContainer />
    </AppProviders>
  )
}
