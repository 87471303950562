import CZ from "./cz"
import DK from "./dk"
import EU from "./eu"
import FI from "./fi"
import INT from "./int"
import NO from "./no"
import SE from "./se"
import UK from "./uk"

export const markets = { CZ, DK, EU, FI, INT, NO, SE, UK }
