// @ts-ignore
import GlacialIndifferenceWoff2 from "../../static/fonts/glacial-indifference/glacialindifference-regular-webfont.woff2"
// @ts-ignore
import GlacialIndifferenceWoff from "../../static/fonts/glacial-indifference/glacialindifference-regular-webfont.woff"
// @ts-ignore
import GlacialIndifferenceTTF from "../../static/fonts/glacial-indifference/glacialindifference-regular-webfont.ttf"
// @ts-ignore
import GlacialIndifferenceBoldWoff2 from "../../static/fonts/glacial-indifference/glacialindifference-bold-webfont.woff2"
// @ts-ignore
import GlacialIndifferenceBoldWoff from "../../static/fonts/glacial-indifference/glacialindifference-bold-webfont.woff"
// @ts-ignore
import GlacialIndifferenceItalicWoff2 from "../../static/fonts/glacial-indifference/glacialindifference-italic-webfont.woff2"
// @ts-ignore
import GlacialIndifferenceItalicWoff from "../../static/fonts/glacial-indifference/glacialindifference-italic-webfont.woff"
// @ts-ignore
import GlacialIndifferenceItalicTTF from "../../static/fonts/glacial-indifference/glacialindifference-italic-webfont.ttf"
// @ts-ignore
import GlacialIndifferenceCZEOT from "../../static/fonts/glacial-indifference-cz/GlacialIndifference-RegularCZ.eot"
// @ts-ignore
import GlacialIndifferenceCZWoff2 from "../../static/fonts/glacial-indifference-cz/GlacialIndifference-RegularCZ.woff2"
// @ts-ignore
import GlacialIndifferenceCZWoff from "../../static/fonts/glacial-indifference-cz/GlacialIndifference-RegularCZ.woff"
// @ts-ignore
import GlacialIndifferenceCZTTF from "../../static/fonts/glacial-indifference-cz/GlacialIndifference-RegularCZ.ttf"
// @ts-ignore
import GlacialIndifferenceCZSvg from "../../static/fonts/glacial-indifference-cz/GlacialIndifference-RegularCZ.svg"
// @ts-ignore
import GlacialIndifferenceCZBoldEOT from "../../static/fonts/glacial-indifference-cz/GlacialIndifference-BoldCZ.eot"
// @ts-ignore
import GlacialIndifferenceCZBoldWoff2 from "../../static/fonts/glacial-indifference-cz/GlacialIndifference-BoldCZ.woff2"
// @ts-ignore
import GlacialIndifferenceCZBoldWoff from "../../static/fonts/glacial-indifference-cz/GlacialIndifference-BoldCZ.woff"
// @ts-ignore
import GlacialIndifferenceCZBoldTTF from "../../static/fonts/glacial-indifference-cz/GlacialIndifference-BoldCZ.ttf"
// @ts-ignore
import GlacialIndifferenceCZBoldSvg from "../../static/fonts/glacial-indifference-cz/GlacialIndifference-BoldCZ.svg"
// @ts-ignore
import ChainPrinterRegularTTF from "../../static/fonts/chainprinter/chainprinter-regular.ttf"

export default {
  GlacialIndifferenceWoff2,
  GlacialIndifferenceWoff,
  GlacialIndifferenceTTF,

  GlacialIndifferenceBoldWoff2,
  GlacialIndifferenceBoldWoff,

  GlacialIndifferenceItalicWoff2,
  GlacialIndifferenceItalicWoff,
  GlacialIndifferenceItalicTTF,

  GlacialIndifferenceCZEOT,
  GlacialIndifferenceCZWoff2,
  GlacialIndifferenceCZWoff,
  GlacialIndifferenceCZTTF,
  GlacialIndifferenceCZSvg,

  GlacialIndifferenceCZBoldEOT,
  GlacialIndifferenceCZBoldWoff2,
  GlacialIndifferenceCZBoldWoff,
  GlacialIndifferenceCZBoldTTF,
  GlacialIndifferenceCZBoldSvg,

  ChainPrinterRegularTTF
}
