import React from "react"
import styled from "styled-components"
import { Link } from "gatsby"
import { motion } from "framer-motion"

export const Columns = ({
  links,
  onHover,
}: {
  links: unknown[]
  onHover: (value: boolean) => void
}) => {
  if (links.length === 0) {
    return null
  }

  const textLinks = links
    // .filter((link) => !link.image)
    .sort((a, b) => (a.position_header || 0) - (b.position_header || 0))
    .map((link) => (
      <li key={link.id}>
        <TextLink to={`/${link.url}`}>{link.name}</TextLink>
      </li>
    ))

  // const imageLinks = links
  // 	// .filter((link) => !!link.image)
  // 	.sort((a, b) => (a.position_header || 0) - (b.position_header || 0))
  // 	.map((link) => (
  // 		<ImageLink key={link.id} to={link.url}>
  // 			<Image fixed={link.image?.localFile.childImageSharp.fixed} />
  // 			<Overlay>
  // 				<span>{link.name}</span>
  // 			</Overlay>
  // 		</ImageLink>
  // 	))

  return (
    <ColumnsMenu
      key={links[0].id}
      onMouseEnter={() => onHover(true)}
      onMouseLeave={() => onHover(false)}
      initial={{ opacity: 0, height: 0 }}
      animate={{ opacity: 1, height: "auto" }}
      exit={{ opacity: 0, height: 0 }}
      transition={{ duration: 0.3 }}
    >
      <Container>
        <ul>{textLinks.slice(0, 5)}</ul>
        <ul>{textLinks.slice(5, 10)}</ul>
        {/* {imageLinks} */}
      </Container>
    </ColumnsMenu>
  )
}

const ColumnsMenu = styled(motion.div)`
  width: 100%;
  background-color: #eeeeee;
  position: absolute;
  left: 0;
  top: 100px;
  padding: 50px 0;
  box-sizing: border-box;
  z-index: 4;
  overflow: hidden;
`

const Container = styled.nav`
  width: 1220px;
  margin: auto;
  display: flex;
  justify-content: space-between;
`

const TextLink = styled(Link)`
  font-size: 1.3rem;
  color: #242424;
  text-decoration: none;
  display: block;
  padding: 5px;

  &:hover {
    color: #98896f;
  }
`

const ImageLink = styled(Link)`
  color: #fff;
  font-size: 3rem;
  display: block;
  text-decoration: none;
  position: relative;
`

const Overlay = styled.div`
  display: flex;
  position: absolute;
  top: 0;
  left: 0;
  width: 385px;
  height: 175px;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.3);
  transition: all 0.3s ease-in-out 0s;

  &:hover {
    background-color: transparent;
  }
`
