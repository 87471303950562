import { Language } from "./type"

export default {
  name: "Français",
  iconName: "fr",
  subFolder: `fr`,

  languageCode: `fr`,
  hreflang: "fr",

  mapDefaultCenterLat: 48.85341,
  mapDefaultCenterLng: 2.3488,

  slugs: {
    about: "a-propos",
    products: "produits",
    product: "produit",
    magazine: "magazine",
    athletes: "athletes",
    stores: "magasins",
    materials: "materiaux",
    faq: "faq",
    jobs: "jobs",
    contact: "contact",
    calendar: "calendrier-activites",
    responsibility: "notre-responsabilite",
    forHumans: "pour-les-humains",
    forDogs: "pour-les-chiens",
    christmasCalendar: "christmas-gift-guide",
  },
  marketId: process.env.GATSBY_EU_MARKET_ID,
  countryCode: "FR",
  availableShippingCountries: [
    "FR",
    "GF",
    "GP",
    "MF",
    "MQ",
    "NC",
    "RE",
    "TF",
    "YT",
  ],
  shippingCost: "14.9",
  freeShippingMinimum: "60",

  cartUrl: "https://www.nonstopdogwear.com/fr/",
  returnUrl: "https://www.nonstopdogwear.com/fr/produits/",
  privacyUrl:
    "https://www.nonstopdogwear.com/fr/declaration-de-confidentialite/",
  termsUrl: "https://www.nonstopdogwear.com/fr/conditions-dachat/",

  priceField: "europe_eur",
  currency: "EUR",
} as Language
