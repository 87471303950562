import React from "react"
import { ThemeProvider } from "styled-components"
import CartProvider from "./cartContext"
import { LocationProvider } from "./locationContext"
import { neutralPalette } from "../styles/variables"

export default ({
  children,
  location,
}: {
  children: React.ReactNode
  location: Location
}) => {
  return (
    <ThemeProvider theme={neutralPalette}>
      <LocationProvider location={location}>
        <CartProvider>{children}</CartProvider>
      </LocationProvider>
    </ThemeProvider>
  )
}
