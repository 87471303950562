import {
  CommerceLayer,
  OrderContainer,
  OrderStorage,
} from "@commercelayer/react-components"
import React, { ReactNode } from "react"
import { getUserAccessToken } from "../../services/commerceLayer/customerAuth"
import SideCart from "../cart/SideCart"
import Header from "../Header"
import { LayoutContextType } from "../layout"
import { useGetSalesChannelToken } from "../../hooks/getSalesChannelToken"

export const isBrowser = () => typeof window !== "undefined"

const endpoint = process.env.GATSBY_COMMERCELAYER_BASE_URL
const returnUrl = process.env.GATSBY_COMMERCELAYER_RETURN_URL
if (!endpoint) {
  throw new Error("GATSBY_COMMERCELAYER_BASE_URL is undefined")
}

if (!returnUrl) {
  throw new Error("GATSBY_COMMERCELAYER_RETURN_URL is undefined")
}

function CommerceLayerLayout({
  children,
  pageContext,
}: {
  children: ReactNode
  pageContext: LayoutContextType
}) {
  const token = getUserAccessToken() || useGetSalesChannelToken()
  if (!isBrowser()) {
    return (
      <CommerceLayer
        accessToken={token || ""}
        endpoint={endpoint || ""}
      >
        <OrderContainer attributes={{ return_url: `${returnUrl}` }}>
          <Header activity={pageContext?.data || ""} />
          <SideCart />
          <main>{children}</main>
        </OrderContainer>
      </CommerceLayer>
    )
  }
  return (
    <CommerceLayer
      accessToken={token || ""}
      endpoint={endpoint || ""}
    >
      <OrderStorage persistKey="nonstop-defense-store">
        <OrderContainer attributes={{ return_url: `${returnUrl}` }}>
          <Header activity={pageContext?.data || ""} />
          <SideCart />
          <main>{children}</main>
        </OrderContainer>
      </OrderStorage>
    </CommerceLayer>
  )
}

export default CommerceLayerLayout
