import Czech from "./cs"
import English from "./en"
import Finnish from "./fi"
import French from "./fr"
import German from "./de"
import Norwegian from "./no"
import Russian from "./ru"
import Spanish from "./es"
import Swedish from "./sv"

export default {
  Czech,
  German,
  English,
  Spanish,
  Finnish,
  French,
  Norwegian,
  Swedish,
  Russian,
}
