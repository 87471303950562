import { Market } from "./type"

export default {
  name: "Europe",
  marketId: process.env.GATSBY_EU_MARKET_ID,
  countryCode: "",
  availableShippingCountries: [
    "IE",
    "ES",
    "AD",
    "AL",
    "AT",
    "AX",
    "BA",
    "BE",
    "BG",
    "BY",
    "CH",
    "CY",
    "EE",
    "DE",
    "GR",
    "HR",
    "HU",
    "IS",
    "IT",
    "LT",
    "LU",
    "LV",
    "MC",
    "MD",
    "ME",
    "MT",
    "NL",
    "PL",
    "PT",
    "RO",
    "RS",
    "SI",
    "SM",
    "SX",
    "VA",
    "FR",
    "GF",
    "GP",
    "MF",
    "MQ",
    "NC",
    "RE",
    "TF",
    "YT",
  ],
  shippingCost: "14.9",
  freeShippingMinimum: "60",
  cartUrl: "https://www.nonstopdogwear.com/en/",
  returnUrl: "https://www.nonstopdogwear.com/en/products/",
  privacyUrl: "https://www.nonstopdogwear.com/en/privacy/",
  termsUrl: "https://www.nonstopdogwear.com/en/terms/",
  priceField: "europe_eur",
  currency: "EUR",
} as Market
