import { Language } from "./type"

export default {
  name: "Russia",
  iconName: "ru",
  subFolder: `ru`,
  marketId: "",
  languageCode: `ru`,
  hreflang: "ru",
  countryCode: "",
  availableShippingCountries: [""],
  shippingCost: "14.9",
  freeShippingMinimum: "60",

  cartUrl: "https://www.nonstopdogwear.com/ru/",
  returnUrl: "https://www.nonstopdogwear.com/ru/products/",
  privacyUrl: "https://www.nonstopdogwear.com/ru/privacy/",
  termsUrl: "https://www.nonstopdogwear.com/ru/terms/",
  mapDefaultCenterLat: 55.5807481,
  mapDefaultCenterLng: 36.8251304,
  priceField: "international_usd",
  currency: "USD",

  slugs: {
    about: "o-nas",
    products: "produkty",
    product: "produkt",
    magazine: "blog",
    athletes: "sportsmeny",
    stores: "magaziny",
    materials: "materialy",
    faq: "faq",
    jobs: "rabota-u-nas",
    contact: "kontakty",
    calendar: "kalendar",
    responsibility: "politika-kompanii",
    forHumans: "dlja-sobak",
    forDogs: "dlja-muzhchin-i-zhenshhin",
    christmasCalendar: "guid-po-rozdyestvyenskim",
  },
} as Language
