import styled from "styled-components"

export const DesktopOnly = styled.div`
  display: none;
  @media (min-width: ${({ theme }) => theme.screens.xl}) {
    display: block;
  }
`

export const TabletOnly = styled.div`
  display: none;
  @media (min-width: ${({ theme }) => theme.screens.md}) {
    display: block;
  }

  @media (min-width: ${({ theme }) => theme.screens.xl}) {
    display: none;
  }
`

export const MobileOnly = styled.div`
  @media (min-width: ${({ theme }) => theme.screens.md}) {
    display: none;
  }
`

export const NotMobile = styled.div`
  display: none;
  @media (min-width: ${({ theme }) => theme.screens.md}) {
    display: block;
  }
`
