import Cookies from "js-cookie"
import { navigate } from "gatsby"
import { toast } from "react-toastify"

type CustomerType = { username: string; password: string }

export const isBrowser = () => typeof window !== "undefined"

export const getUserAccessToken = () =>
  isBrowser() ? Cookies.get("cl_access_token_password") : ""

export const isCustomerLoggedIn = () => {
  const token = getUserAccessToken()

  return !!token
}

export const logoutCustomer = () => {
  Cookies.remove(`cl_access_token_password`)
  toast.success("You have been logged out")
  navigate("/login")
}
