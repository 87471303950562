import { Market } from "./type"

export default {
  name: "Czech Republic",
  marketId: process.env.GATSBY_CZ_MARKET_ID,
  countryCode: "",
  availableShippingCountries: ["CZ", "SK"],
  shippingCost: "400",
  freeShippingMinimum: "1600",
  cartUrl: "https://www.nonstopdogwear.com/cz/",
  returnUrl: "https://www.nonstopdogwear.co/cz/produkty/",
  privacyUrl: "https://www.nonstopdogwear.com/cz/prohlaseni/",
  termsUrl: "https://www.nonstopdogwear.com/cz/smluvni-podminky/",
  priceField: "czech_republic_czk",
  currency: "CZK",
} as Market
