import { Language } from "./type"

export default {
  name: "Deutsch",
  iconName: "de",
  subFolder: `de`,
  languageCode: `de`,
  hreflang: "de",

  mapDefaultCenterLat: 52.52437,
  mapDefaultCenterLng: 13.41053,

  slugs: {
    about: "uber-uns",
    products: "produkte",
    product: "produkt",
    magazine: "magazin",
    athletes: "athleten",
    stores: "handler",
    materials: "materialien",
    faq: "faq",
    jobs: "jobs",
    contact: "kontakt",
    calendar: "activity-calendar",
    responsibility: "unser-verantwortungsbewusstsein",
    forHumans: "fur-menschen",
    forDogs: "fur-hunde",
    christmasCalendar: "weihnachtsgeschenkideen",
  },

  marketId: process.env.GATSBY_EU_MARKET_ID,
  countryCode: "",
  availableShippingCountries: [
    "IE",
    "AD",
    "AL",
    "AT",
    "AX",
    "BA",
    "BE",
    "BG",
    "BY",
    "CH",
    "CY",
    "EE",
    "GR",
    "HR",
    "HU",
    "IS",
    "IT",
    "LT",
    "LU",
    "LV",
    "MC",
    "MD",
    "ME",
    "MT",
    "NL",
    "PL",
    "PT",
    "RO",
    "RS",
    "SI",
    "SM",
    "SX",
    "VA",
  ],
  shippingCost: "14.9",
  freeShippingMinimum: "60",
  cartUrl: "https://www.nonstopdogwear.com/en/",
  returnUrl: "https://www.nonstopdogwear.com/en/products/",
  privacyUrl: "https://www.nonstopdogwear.com/en/privacy/",
  termsUrl: "https://www.nonstopdogwear.com/en/terms/",
  priceField: "europe_eur",
  currency: "EUR",
} as Language
