import { Language } from "./type"

export default {
  name: "Čeština",
  iconName: "cz",
  subFolder: `cz`,
  languageCode: `cs`,
  hreflang: "cs",
  mapDefaultCenterLat: 50.08804,
  mapDefaultCenterLng: 14.42076,

  slugs: {
    about: "o-nas",
    products: "produkty",
    product: "produkt",
    magazine: "casopis",
    athletes: "atleti",
    stores: "obchody",
    materials: "materialy",
    faq: "faq",
    jobs: "jobs",
    contact: "kontakt",
    calendar: "kalendar",
    responsibility: "nase-odpovednost",
    forHumans: "pro-lidi",
    forDogs: "pro-psy",
    christmasCalendar: "christmas-gift-guide",
  },

  marketId: process.env.GATSBY_CZ_MARKET_ID,
  countryCode: "",
  availableShippingCountries: ["CZ", "SK"],
  shippingCost: "400",
  freeShippingMinimum: "1600",
  cartUrl: "https://www.nonstopdogwear.com/cz/",
  returnUrl: "https://www.nonstopdogwear.co/cz/produkty/",
  privacyUrl: "https://www.nonstopdogwear.com/cz/prohlaseni/",
  termsUrl: "https://www.nonstopdogwear.com/cz/smluvni-podminky/",
  priceField: "czech_republic_czk",
  currency: "CZK",
} as Language
