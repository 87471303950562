import React from "react"
import styled from "styled-components"
import { graphql, useStaticQuery } from "gatsby"

import Container from "./Container"
import FooterColumn from "./navigation/FooterColumn"
import { NewsletterLine } from "./newsletter/NewsletterLine"
import TermsLine from "./navigation/TermsLine"

export default function Footersection() {
  const { allStrapiMainMenuLink } = useStaticQuery<Queries.MainMenuLinksQuery>(
    graphql`
      query MainMenuLinks {
        allStrapiMainMenuLink(
          sort: { fields: position_footer }
          filter: { display_in_footer: { eq: true } }
        ) {
          edges {
            node {
              display
              display_in_footer
              display_in_header
              dropdown
              id
              name
              strapi_id
              style
              url
              main_submenu_links {
                strapi_id
                url
                display_in_footer
                display
                display_in_header
                name
              }
            }
          }
        }
      }
    `
  )

  const columns = allStrapiMainMenuLink.edges.map((menuLink) => {
    const {
      name,
      url,
      main_submenu_links,
      strapi_id: menuLinkId,
    } = menuLink.node
    const subMenuLinks = main_submenu_links
      ? main_submenu_links.map((subMenu) => {
          if (!subMenu) return null
          return {
            id: subMenu.strapi_id,
            name: subMenu.name,
            href: `/${subMenu.url}`,
          }
        })
      : []
    const subMenuData = [subMenuLinks]
    const column = {
      id: menuLinkId,
      topLevel: name,
      url,
      subMenu: subMenuData,
    }

    return (
      <FooterColumn
        key={menuLinkId}
        column={column}
      />
    )
  })

  return (
    <>
      <NewsletterLine />
      <Footer as="footer">{columns}</Footer>

      <TermsLine />
    </>
  )
}

const Footer = styled(Container)`
  background-color: #fff;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;

  & > div:last-of-type {
    margin: 45px 0;

    @media (min-width: ${({ theme }) => theme.screens.mmd}) {
      margin: 0 0 0 18px;
    }
  }
`

export const SocialIcons = styled.div`
  & a + a {
    margin-left: 15px;
  }
`
