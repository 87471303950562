import axios from "axios"
import { getAccessToken } from "./auth"
import { fetchOrder, getRecommendedSize } from "./order"

export type LineItem = {
  id: string
  type: string
  attributes: {
    sku_code: string
    quantity: number
    unit_amount_cents: number
    metadata?: { [key: string]: unknown }
    name: string | null
    image_url: string | null
    item_type: string
  }
}

export type LineItemWithInventory = LineItem & {
  relationships: {
    item: { data: { type: string; id: string } }
  }
  inventory?: number | undefined
}

export async function createLineItem({
  marketId,
  orderId,
  sku,
}: {
  marketId?: string
  orderId: string
  sku: string
}) {
  if (!marketId) {
    throw new Error("No market Id for line item")
  }
  const accessToken = await getAccessToken(marketId)
  await axios.post<{
    data: LineItem
  }>(
    `${process.env.GATSBY_COMMERCELAYER_BASE_URL}/api/line_items/`,
    {
      data: {
        type: "line_items",
        attributes: {
          sku_code: sku,
          quantity: 1,
        },
        relationships: {
          order: {
            data: {
              type: "orders",
              id: orderId,
              attributes: {
                metadata: {
                  recommendedSize: [getRecommendedSize()],
                },
              },
            },
          },
        },
      },
    },
    {
      headers: {
        "Authorization": `Bearer ${accessToken}`,
        "Content-Type": "application/vnd.api+json",
      },
    }
  )
  return fetchOrder({ orderId, marketId })
}

export async function deleteLineItem({
  id,
  marketId,
  orderId,
}: {
  id: string
  marketId?: string
  orderId: string
}) {
  if (!marketId) {
    throw new Error("No market Id for line item")
  }
  const accessToken = await getAccessToken(marketId)
  await axios.delete(
    `${process.env.GATSBY_COMMERCELAYER_BASE_URL}/api/line_items/${id}`,
    {
      headers: {
        "Authorization": `Bearer ${accessToken}`,
        "Content-Type": "application/vnd.api+json",
      },
    }
  )
  return fetchOrder({ orderId, marketId })
}

export async function updateLineItem({
  id,
  marketId,
  orderId,
  quantity,
}: {
  id: string
  marketId?: string
  orderId: string
  quantity: number
}) {
  if (!marketId) {
    throw new Error("No market Id for line item")
  }
  const accessToken = await getAccessToken(marketId)
  try {
    await axios.patch<{
      data: LineItem
    }>(
      `${process.env.GATSBY_COMMERCELAYER_BASE_URL}/api/line_items/${id}`,
      {
        data: {
          id,
          type: "line_items",
          attributes: { quantity },
        },
      },
      {
        headers: {
          "Authorization": `Bearer ${accessToken}`,
          "Content-Type": "application/vnd.api+json",
        },
      }
    )
  } catch (error) {
    //console.error(error)
  } finally {
    return fetchOrder({ orderId, marketId })
  }
}
