import { createGlobalStyle } from "styled-components"
import reset from "./reset"
import normalize from "./normalize"
import main from "./main"
import styles from "./styles"
import fontFiles from "./fonts"

export default createGlobalStyle`
	${reset}
	${normalize}
	${main}
	
	@font-face {
		font-family: "Glacial Indifference";
		src: url(${fontFiles.GlacialIndifferenceTTF});
		src: url(${fontFiles.GlacialIndifferenceWoff2}) format("woff2"),
			 url(${fontFiles.GlacialIndifferenceWoff}) format("woff");
		font-weight: normal;
	}

	@font-face {
		font-family: "Glacial Indifference";
		src: url(${fontFiles.GlacialIndifferenceBoldWoff2});
		src: url(${fontFiles.GlacialIndifferenceBoldWoff}) format("woff");
		font-weight: bold;
	}

	@font-face {
		font-family: "Glacial Indifference";
		src: url(${fontFiles.GlacialIndifferenceItalicTTF});
		src: url(${fontFiles.GlacialIndifferenceItalicWoff2}) format("woff2"),
			 url(${fontFiles.GlacialIndifferenceItalicWoff}) format("woff");
	    font-style: italic;

	}

	@font-face {
		font-family: "Glacial Indifference Czech";
		src: url(${fontFiles.GlacialIndifferenceCZEOT});
		src: url(${fontFiles.GlacialIndifferenceCZWoff2}) format("woff2"),
			 url(${fontFiles.GlacialIndifferenceCZWoff}) format("woff2"),
			 url(${fontFiles.GlacialIndifferenceCZTTF}) format("truetype"),
			 url(${fontFiles.GlacialIndifferenceCZSvg}) format("svg");
		font-weight: normal;
	}

	@font-face {
		font-family: "Glacial Indifference Czech";
		src: url(${fontFiles.GlacialIndifferenceCZBoldEOT});
		src: url(${fontFiles.GlacialIndifferenceCZBoldWoff2}) format("woff2"),
			 url(${fontFiles.GlacialIndifferenceCZBoldWoff}) format("woff2"),
			 url(${fontFiles.GlacialIndifferenceCZBoldTTF}) format("truetype"), 
			 url(${fontFiles.GlacialIndifferenceCZBoldSvg}) format("svg");
		font-weight: bold;
	}

	@font-face {
		font-family: "ChainPrinterRegular";		
		src: url(${fontFiles.ChainPrinterRegularTTF}) format("truetype");
		font-weight: normal;
	}
	
	${styles}

`
