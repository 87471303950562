import React, { useState } from "react"
import { navigate } from "gatsby"
import { checkEmailFormat } from "../../utils/email"

export function NewsletterInlineForm() {
  const [email, setEmail] = useState("")
  const [subscribeSuccess, setSubscribeSuccess] = useState(false)

  const onSubmit = async (e: React.FormEvent) => {
    // e.preventDefault()
    // setSubscribeSuccess(true)
  }

  return (
    <div>
      <span className="title">Newsletter</span>
      {!subscribeSuccess ? (
        <>
          <div>
            <form
              action={process.env.MAILCHIMP_FORM_URL}
              method="post"
              id="mc-embedded-subscribe-form"
              name="mc-embedded-subscribe-form"
              className="validate"
              target="_blank"
              noValidate
              onSubmit={(e) => onSubmit(e)}
            >
              <input
                aria-label="Your email address"
                type="email"
                placeholder="Your email address"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                required
                name="EMAIL"
                className="required email"
                id="mce-EMAIL"
              />
              <input
                type="submit"
                value="Go"
                name="subscribe"
                disabled={!checkEmailFormat(email)}
              />
              <div
                style={{ position: "absolute", left: "-5000px" }}
                aria-hidden="true"
              >
                <input
                  type="text"
                  name="b_7dd7bd274fdcb0ec7dc9cc77b_8f563ae70f"
                  tabIndex={-1}
                />
              </div>
            </form>
          </div>
          <p className="description">
            Sign up to learn about new products, upcoming events, and other news
            from Non-stop defense.
          </p>
        </>
      ) : (
        <p className="description">
          Thanks for subscribing to our newsletter!.
        </p>
      )}
    </div>
  )
}
