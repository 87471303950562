import React from "react"
import { Columns } from "./ColumnsSubMenu"
import { Grid } from "./GridSubMenu"
import { Angle } from "./AngleSubMenu"

export const SubMenu = ({
  link,
  onHover,
}: {
  link: unknown
  onHover: (value: boolean) => void
}) => {
  if (link.style === "Columns") {
    return (
      <Columns
        onHover={(value) => onHover(value)}
        links={link.main_submenu_links}
      />
    )
  }

  if (link.style === "Grid") {
    return (
      <Grid
        onHover={(value) => onHover(value)}
        links={link.main_submenu_links}
      />
    )
  }

  if (link.style === "Angle") {
    return (
      <Angle
        onHover={(value) => onHover(value)}
        links={link.main_submenu_links}
      />
    )
  }
  return null
}
