import cookies from "js-cookie"

export function getAccessTokenCookie(marketId: string): string | undefined {
  return cookies.get(
    `cl_access_token_${process.env.GATSBY_COMMERCELAYER_CLIENT_ID}_${marketId}`
  )
}

export function setAccessTokenCookie(
  accessToken: string,
  expires: number,
  marketId: string
) {
  cookies.set(
    `cl_access_token_${process.env.GATSBY_COMMERCELAYER_CLIENT_ID}_${marketId}`,
    accessToken,
    { expires, sameSite: "strict", secure: true }
  )
}

export function getOrderCookie({
  marketId,
  countryCode,
}: {
  marketId: string
  countryCode: string
}): string | undefined {
  return cookies.get(
    `order_token_${process.env.GATSBY_COMMERCELAYER_CLIENT_ID}_${marketId}_${countryCode}`
  )
}

export function setOrderCookie(
  countryCode: string,
  orderId: string,
  marketId: string
) {
  cookies.set(
    `order_token_${process.env.GATSBY_COMMERCELAYER_CLIENT_ID}_${marketId}_${countryCode}`,
    orderId,
    { expires: 30, sameSite: "strict", secure: true }
  )
}

export function removeOrderCookie(countryCode: string, marketId: string) {
  cookies.remove(
    `order_token_${process.env.GATSBY_COMMERCELAYER_CLIENT_ID}_${marketId}_${countryCode}`
  )
}
