import React, { useEffect, useState, Fragment } from "react"
import styled from "styled-components"
import _ from "lodash-es"
import { useCart } from "../../context/cartContext"
import { formatCurrency } from "../../helpers"

import HeadingLine from "../HeadingLineStyles"
import LineItems from "./LineItems"
import {
  CheckoutLink,
  SubTotalAmount,
  TotalAmount,
  ShippingAmount,
  LineItemsContainer,
  Errors,
} from "@commercelayer/react-components"
export default () => {
  const {
    isCartVisible,
    setCartVisible,
    order,
    shippingCountry,
    market: { currency, shippingCost },
  } = useCart()
  let {
    market: { freeShippingMinimum },
  } = useCart()

  let shippingAmount = "-"
  let freeShippingMessage = ""
  let orderTotal = "-"

  if (order?.attributes.subtotal_amount_cents) {
    const hasOnlyGiftCards = order?.lineItems.every((li) =>
      li.attributes.sku_code.startsWith("gift")
    )

    if (hasOnlyGiftCards) {
      shippingAmount = formatCurrency({
        amount: 0,
      })
      orderTotal = formatCurrency({
        amount: order.attributes.total_amount_with_taxes_cents / 100,
      })
    } else {
      if (
        order.attributes.subtotal_amount_cents <
        Number(freeShippingMinimum) * 100
      ) {
        shippingAmount = formatCurrency({
          amount: Number(shippingCost),
        })
        freeShippingMessage = "Free shipping above"
        orderTotal = formatCurrency({
          amount:
            order.attributes.subtotal_amount_cents / 100 + Number(shippingCost),
        })
      } else {
        shippingAmount = formatCurrency({
          amount: 0,
        })
        orderTotal = formatCurrency({
          amount: order.attributes.total_amount_with_taxes_cents / 100,
        })
      }
    }
  }

  return (
    <Overlay $isCartVisible={isCartVisible}>
      <OverlayEffect onClick={() => setCartVisible(false)} />
      <SideCart>
        <BackToShoppingBtn
          type="button"
          onClick={() => setCartVisible(false)}
        >
          🡰 Continue shopping
        </BackToShoppingBtn>
        <LineItemsContainer>
          <Errors
            resource="line_items"
            field=""
          >
            {({ errors }) => (
              <ErrorsContainer $hasError={!!errors.length}>
                <span>{_.upperFirst(errors.length ? errors[0] : "")}</span>
              </ErrorsContainer>
            )}
          </Errors>
          <CartItems>
            <HeadingLine as="span">Cart</HeadingLine>
            <LineItems />
          </CartItems>
        </LineItemsContainer>
        <Recap>
          <table>
            <tbody>
              <tr>
                <th>Subtotal</th>
                <td>
                  <SubTotalAmount />
                </td>
              </tr>
              <tr>
                <th>Shipping</th>
                <td>
                  To be calculated
                  {/* <ShippingAmount /> */}
                </td>
              </tr>
              <tr>
                <td colSpan="2">
                  <small>{freeShippingMessage}</small>
                </td>
              </tr>
            </tbody>
            <tfoot>
              <tr>
                <th>
                  <strong>Total</strong>
                </th>
                <td>
                  <strong>
                    <TotalAmount />
                  </strong>
                </td>
              </tr>
            </tfoot>
          </table>
          <CheckoutButton
            label="Buy now"
            className="checkout-link"
          />
        </Recap>
      </SideCart>
    </Overlay>
  )
}

const ErrorsContainer = styled.div<{ $hasError: boolean }>`
  display: ${({ $hasError }) => ($hasError ? "block" : "none")};
  padding: 10px;
  background: #dc3545;
  margin-bottom: 10px;
  text-align: center;
  & span {
    font-size: 14px;
    color: #ffffff;
  }
`

const OverlayEffect = styled.div`
  height: 100%;
  position: fixed;
  background: rgba(0, 0, 0, 0.6);
  width: 100%;
`
const SideCart = styled.div`
  height: 100%;
  position: fixed;
  background: #fff;
  width: 400px;
  max-width: 100%;
  z-index: 12;
  display: flex;
  flex-direction: column;

  & > button {
    width: 100%;
    margin-bottom: 20px;
    background-color: #f8f8f8;
    border: 0;
    text-transform: uppercase;
    font-size: ${({ theme }) => theme.fontBase};
    padding: 20px 50px;
    cursor: pointer;
    box-sizing: border-box;

    & :hover {
      background-color: #f0f0f0;
    }
  }
`

const Overlay = styled.div<{ $isCartVisible: boolean }>`
  position: fixed;
  top: 0;
  left: 0;
  z-index: 11;
  width: 100%;
  height: 100%;
  visibility: ${({ $isCartVisible }) =>
    $isCartVisible ? "visible" : "hidden"};

  & ${SideCart} {
    right: ${({ $isCartVisible }) => ($isCartVisible ? "0" : "-400px")};
    transition: 1s all ease;
  }
`

const Recap = styled.div`
  padding: 20px 50px;
  width: 100%;
  background-color: #f8f8f8;
  box-sizing: border-box;
  color: #939393;
  & > table {
    font-size: ${({ theme }) => theme.fontBase};
    width: 100%;
    & strong {
      font-weight: bold;
    }

    & em {
      font-style: italic;
    }

    & tfoot {
      border-top: 1px #939393 solid;
      color: #232323;
      td {
        padding: 15px 0;
      }
    }

    & th {
      text-align: left;
    }

    & td {
      text-align: right;
      padding: 8px 0;
    }

    & small {
      font-size: 1.4rem;
    }
  }
`
const CartItems = styled.div`
  width: 100%;
  flex: 1;
  padding: 0 50px;
  font-size: ${({ theme }) => theme.fontBase};
  box-sizing: border-box;
  overflow-y: scroll;
`

const CheckoutButton = styled(CheckoutLink)`
  display: block;
  text-align: center;
  width: 100%;
  background-color: ${({ theme }) => theme.contrastColor};
  color: #fff;
  text-transform: uppercase;
  font-size: ${({ theme }) => theme.fontBase};
  padding: 0.5rem;
  border: none;
  text-decoration: none;
  box-sizing: border-box;
  margin-top: 20px;

  &:hover {
    background-color: ${({ theme }) => theme.contrastColorDarker10};
  }
  &:disabled {
    background-color: ${({ theme }) => theme.contrastColor};
    &:hover {
      cursor: not-allowed;
    }
  }
`

const BackToShoppingBtn = styled.button`
  color: ${({ theme }) => theme.primaryColor};
`
