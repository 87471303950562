import axios from "axios"
import { getAccessTokenCookie, setAccessTokenCookie } from "./cookies"

export async function getAccessToken(marketId: string | undefined) {
  if (!marketId) {
    throw new Error("No market Id provided")
  }
  let accessToken = getAccessTokenCookie(marketId)
  if (accessToken) {
    return accessToken
  }

  return generateAccessToken(marketId)
}

async function generateAccessToken(marketId: string) {
  const response = axios.post<{
    access_token: string
    token_type: string
    expires_in: number
    scope: string
    created_at: number
  }>(`${process.env.GATSBY_COMMERCELAYER_BASE_URL}/oauth/token`, {
    grant_type: "client_credentials",
    client_id: process.env.GATSBY_COMMERCELAYER_CLIENT_ID,
    scope: `market:${marketId}`,
  })

  const auth = (await response).data

  setAccessTokenCookie(
    auth.access_token,
    auth.expires_in / (60 * 60 * 24), //Convert seconds to days
    marketId
  )
  return auth.access_token
}
