export default `
body {
	/* Define colors as colors */
	--black: #242424;
	--cream: #eeeeee;
	--orange: #fe5000;
	--orangeDark20: #cb1d00;
	--pureBlack: #000;
	--pureWhite: #fff;
	--lightGrey: #e8e8e8;
	--grey: #8e8e8e;
	--tan: #7d7260;
	--brown: #585947;
	--gold: #a9987b;
	/* Define colors intentions */
	--background: var(--cream);
	--textColor: var(--black);
	--lineColor: var(--lightGrey);
	--primary: var(--black);
	--primaryDark20: var(--pureBlack);
	--cardBg: var(--pureWhite);
	--headerBg: var(--black);

	/* Styles */
	--line: 2px solid var(--lineColor);
	--transition: 0.3s ease;


	/* Types */
	--bodyFont: "Glacial Indifference", sans-serif;
	--baseFontSize: 16px;
	--h1: 3rem;
	--h2: 2.5rem;
	--h3: 2rem;
	--h4: 1.5rem;
	--h5: 1.5rem;
	--heading-line: 1rem;
	--smallText: 0.8rem;

	/* Elevation */
	--level--1: inset 0 1px 3px 0 rgba(0, 0, 0, 0.1), inset 0 1px 2px 0 rgba(0, 0, 0, 0.06);
	--level-1: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06);
	--level-2: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06);
	--level-3: 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05);
	--level-4: 0 20px 25px -5px rgba(0, 0, 0, 0.1), 0 10px 10px -5px rgba(0, 0, 0, 0.04);

	/* Breakpoints (not supported)
	mobileLandscape: 640px;
	tabletPortrait: 768px;
	tabletLandscape: 1024px;
	laptop: 1280px;
	*/
}
/* Start typography */

html {
	font-size: var(--baseFontSize);
	box-sizing: border-box;
}


/*18px*/

*,
*::before,
*::after {
	box-sizing: inherit;
}

body {
	font-family: var(--bodyFont);
	font-weight: 400;
	line-height: 1.75;
}

header {
	background-color: var(--headerBg);
	color: var(--pureWhite);
	height: 100px;
	box-shadow: var(--level-2);
}

header .container {
	display: flex;
	justify-content: space-between;
	align-items: center;
	height: 100%;
}

header .logo {
	margin: 0;
}

header nav a {
	color: var(--pureWhite);
}

header nav a:hover {
	color: var(--pureWhite);
	border-bottom: 2px solid var(--tan);
}

header nav a+a {
	margin-left: 10px;
}

footer {
	color: var(--pureWhite);
	--footerBackground: var(--black);
	background-color: var(--headerBg);
	margin-top: 3rem;
}

footer a {
	color: var(--pureWhite);
}

footer a:hover {
	color: var(--pureBlack);
	border-bottom: 2px solid var(--tan);
}

p {
	margin-bottom: 1rem;
}

h1,
h2,
h3,
h4,
h5 {
	margin: 3rem 0 1.38rem;
	font-weight: 400;
	line-height: 1.3;
}

h1 {
	margin-top: 0;
	font-size: var(--h1);
	text-transform: uppercase;
}

h2 {
	margin-top: 0;
	font-size: var(--h2);
	text-transform: uppercase;
}

h3 {
	font-size: var(--h3);
	margin-top: 0;
}

h4 {
	font-size: var(--h4);
	margin-top: 0;
}

h5 {
	font-size: var(--h5);
	margin-top: 0;
}

.line-heading {
	font-size: var(--heading-line);
	text-transform: uppercase;
	display: flex;
	width: 100%;
	align-items: center;
	letter-spacing: 0.25rem;

}

.line-heading::after{
    border-bottom: 1px solid rgb(165, 165, 165);
    margin-right: 50px;
    content: "";
    border-bottom: 1px solid rgb(0, 0, 0);
    flex: 1 0 20px;
	margin-left: 20px;
}
	
small,
.text_small {
	font-size: var(--smallText);
}


/*End typography */

body {
	background-color: var(--pureWhite);
	color: var(--textColor);
}

p {
	max-width: 40rem;
}

a {
	color: var(--primary);
	text-decoration: none;
}

a:hover {
	color: var(--tan)
}

blockquote {
	background-color: var(--cardBg);
	padding: 2rem;
	margin: 10px;
	box-shadow: var(--level-2);
}

label {
	display: block;
	font-size: var(--smallText)
}

input,
textarea {
	border: var(--line);
	background-color: var(--pureWhite);
	padding: 5px 5px;
}

textarea {
	width: 100%;
	height: 5rem;
}

::placeholder {
	color: var(--grey);
}

select {
	border: var(--line)
}

hr {
	border: var(--line)
}

button,
input[type="submit"],
input[type="button"] {
	appearance: none;
	color: var(--pureWhite);
	background-color: var(--primary);
	border: none;
	text-transform: uppercase;
	padding: 5px 25px;
	box-shadow: var(--level-1)
}

button:hover,
input[type="submit"]:hover,
input[type="button"]:hover {
	background-color: var(--brown);
	box-shadow: var(--level-2);
	transition: 0.3s ease-in-out;
}

button:active,
input[type="submit"]:active,
input[type="button"]:active {
	box-shadow: var(--level--1);
	transition: 0.3s ease-in-out;
}

button.big,
input[type="submit"].big,
input[type="button"].big {
	font-size: 1.2rem;
	width: 290px;
	padding: 10px;
}

button[disabled] {
	opacity: 0.4;
}

.container {
	background-color: var(--background);
	color: var(--textColor);
	width: 100%;
	overflow: hidden;
}

.row {
	margin: 0 auto;
	width: 95%;
}

.grid {
	--gridCols: 2;
	display: block;
	grid-template-columns: repeat(var(--gridCols), 1fr);
}

.cols-3 {
	--gridCols: 3;
}

.flex {
	--justifyContent: space-between;
	display: block;
	justify-content: var(--justifyContent);
}

div.card {
	background: var(--cardBg);
	box-shadow: var(--level-3);
	padding: 2rem;
	margin: 20px 0;
	border-left: var(--orange) 4px solid;
}

div.card>*:first-child {
	margin-top: 0;
}

div.card>*:last-child {
	margin-bottom: 0;
}

@media screen and (min-width: 640px) {
	html {
		--baseFontSize: 18px;
	}
	.grid {
		display: grid;
	}
	.flex {
		display: flex;
	}
}

@media screen and (min-width: 1280px) {
	.row {
		width: 1220px;
	}
}

.dark {
	--background: var(--black);
	--textColor: var(--cream);
	--cardBg: var(--pureBlack);
	--headerBg: var(--pureBlack);
}

.pure-white {
	--background: var(--pureWhite);
	--textColor: var(--black);
	--cardBg: var(--pureWhite);
}
`
